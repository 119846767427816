/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { browserHistory } from 'react-router'
// import TreeView from 'react-treeview'
import classnames from 'classnames'
import { MAP } from 'react-google-maps/lib/constants'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import { isMobileOnly } from 'react-device-detect'
import axios from 'axios'
import { FaTruckMoving } from "react-icons/fa"
import { IoIosArrowBack } from "react-icons/io"
import { MdArrowBackIos } from "react-icons/md"

import SimpleMapExampleGoogleMap from './TrackTrace/SimpleMapExampleGoogleMap'
import VehicleSidebar from './TrackTrace/VehicleSidebar'
import TripSnapshots from './TrackTrace/TripSnapshots'
import AdvancedPlayer from '../../component/AdvancedPlayer'
import EventViewSnapshotModal from '../../component/smart/modals/EventViewSnapshotModal'
import SearchBar from '../../component/dump/menuComponents/SearchBar'
import EventShareModal from '../../component/smart/modals/EventShareModal'
import EventNoteModal from '../../component/smart/modals/EventNoteModal'
import GreyHeader from '../../component/dump/menuComponents/GreyHeader'
import useInterval from '../../component/common/UseInterval'
import TreeVehiclesFilter from './TreeVehiclesFilter'
import TreeVehicles from './TreeVehicles'
import LabelTag from '../../component/dump/LabelTag'
import InfoPop from '../../component/common/InfoPop'

import {
	getDevicesMetadata as getDevicesMetadataAction,
	getDevicesList,
	getDevicesLastgps,
	getDevicesMetadataLastKnown,
	getDevicesEventUpdates,
	getDeviceAddress
} from '../../action/DeviceActions'
import { requestCustomAlertVideo } from '../../action/AlertActions'
import { apiCall } from '../../action/RouterActions'
import { detectDeviceStatus, getDeviceDataLimit, getDeviceStatusName, openLiveStream, deviceStatusPopups } from '../../component/helpers/helperVehicle'
import { formatAddress } from '../../component/helpers/tablesFuncHelpers'
import { isInternalUser } from '../../component/helpers/constants'
import { getAvailableFleetStatuses } from '../../component/helpers/helperSleepMode'
import { sendGA } from '../../component/helpers/helperUsers'

import 'react-treeview/react-treeview.css'
import '../../component/dump/menuComponents/treeview.sass'
import '../../component/smart/menuComponentsContainer/markerWithLabel.css'
import 'react-datetime/css/react-datetime.css'

const splitBlocks = (e) => {
	e.preventDefault();

	var x = e.pageX - $('#block-tree').offset().left;
	if (x > -1 && x < 3600 && e.pageX < ($(window).width() - 0)) {
		$('#block-tree').css("width", x);
		$('#map-block').css("left", x);
	}
}

function getRandomInRange(from, to, fixed) {
	return (Math.random() * (to - from) + from).toFixed(fixed) * 1
}

function convertAlertName(alert_name) {
	switch (alert_name) {
		case 'InputSensor':
			return 'DriverEvent';
		case 'FatalGSensor':
			return 'HighGForce';
		case 'HddError':
			return 'SDError';
		default:
			return alert_name;
	}
}

const Dashboard = (props) => {
	const { user, tripAdvancedPlayerEvent, tripShowShareEvent, showTripsHistory, selectedTrip, toggleLoader, dashboardRedirect, setShowTripsHistory } = props;
	const userRole = user.user.roles[0];

	const prevProps = useRef(props)

	const [map, setMap] = useState(null)
	// const [_map, setMap] = useState(null);
	const _map = useRef()
	const [interval, setIntervalState] = useState(null)
	const [delayInterval, setDelayInterval] = useState(null)
	const [timeline, setTimeline] = useState(null)
	const [showFleetVehiclePanels, setShowFleetVehiclePanels] = useState('fleetpanel') // ['','fleetpanel','both']
	const [monthlyFleetDataUsageAvailable, setMonthlyFleetDataUsageAvailable] = useState(0) // FWSD-6748
	const [fleetStatuses, setFleetStatuses] = useState({
		driving: 0,  sleep: 0, offline: 0, 'pending sleep': 0, awake: 0, parked: 0,  disabled: 0, 'pending awake': 0,
	})
	const [state, setState] = useState({
		location: null,
		initDevices: [],
		category: 1,
		autoValue: '',
		groups: [], // list of device groups and related devices to display in the vehicles tree
		// collapsedBookkeeping: [],
		markers: [], // list of devices with icons to display on the google map
		// singleTrucks: [],
		bounds: null,
		alerts: [],
		alertsRemoved: [],
		alert_filters: {
			alert_gsensor: true,
			alert_speed: true
		},
		center: { lat: 37.09024, lng: -95.712891 },
		centerDevice: null,
		zoom: 6,
		maptype: 'roadmap', //'hybrid',
		activeTreeItem: null,

		// showTripPanel: false,
		currentTimelineDate: moment(),
		currentTimelineDateData: moment(),
		alertdevices: [],
		playbackSpeed: 2,
		tripHeight: -14,
		additionalClassName: 'tripReplayPanelClosed',
		polygons: [],
		polygonDevice: null,
		alertshistory: {
			items: [],
			groups: []
		},
		moveTo: moment(),

		alertSearchType: 'tt_local',

		selectedEvent: null,
		showAdvancedPlayer: false,
		showEventNote: false,

		company_trace_active: true,
		showLiveTraffic: false,

		popover: {
			show: false,
			left: 0,
			top: 0,
			type: '',
			content: '',
			event: {
				event_type: '',
				event_device_id: 0,
				event_id: 0,
				event_ts: ''
			}
		},

		showFleetSidebar: true,
		showVehicleSidebar: false,
		showEventVideoAll: false,

		selectedGroupFleetPanel: 'group', 
		filtersFleetPanel: {
			groups:[], 
			statuses:{driving: true, parked: true, sleep: true, awake: true, offline: true, disabled: true /*, 'pending sleep': true, 'pending awake': true*/}
		},
	});

	useEffect(() => {
		// debugger
		// console.log('Dashboard props:', props)
		const { location, tripreplay, company, user } = props
		const { alertdevices, currentTimelineDateData } = state

		traceUserAccess()

		if (location && location.state) {
			/*if (location.state.type === 'redirectFromUnknownDrivers') {
				const locationNew = {
					type: location.state.type,
					unknownDriver: location.state.unknownDriver,
				}	
				setState({...state, location: locationNew })
				browserHistory.replace({ pathname: '/', state: undefined })
			} else*/
			if (location.state.type === 'tripstorage') {
				browserHistory.replace({ pathname: '/', state: undefined })

				window.addEventListener('resize', setMapCenter)
				const toggle = document.getElementsByClassName('toggle-left')[0]
				toggle && toggle.addEventListener('click', setMapCenterTimer)

				setState((prevState) => {
					return {
						...prevState,
						...tripreplay.tripreplay
					}
				})

				const data = {
					device_ids: alertdevices.map((item, i) => item.device_id).join(','),
					start_ts: moment(currentTimelineDateData).utc().subtract(12, 'hours').format('YYYYMMDDHHmmss'),
					end_ts: moment(currentTimelineDateData).utc().add(12, 'hours').format('YYYYMMDDHHmmss'),
				}
				getDevicesMetadataForTrip(
					data,
					function () { }
				)

				props.updateTripreplay(null)

				return false;
			}
		}

		//* The alerts should be "live" where when the alert occurs. The user should never see an alert from hours previous to them logging in to their account.
		// company.company.company_id && getVehicleList(() => getDevicesMetadataOnline(), company.company.company_id)
		company.company.company_id && getVehicleList((markers) => {getDevicesMetadataOnline(markers)}, company.company.company_id)

		if (user.user.settings) {
			const settings = user.user.settings;
			setState((prevState) => {
				return {
					...prevState,
					center: { lat: 37.09024, lng: -95.712891 }, zoom: settings.zoom_level || 6
				}
			})
		}

		window.addEventListener('resize', setMapCenter);
		const toggle = document.getElementsByClassName('toggle-left')[0];
		toggle && toggle.addEventListener('click', setMapCenterTimer);

		return () => {
			if (interval) {
				clearInterval(interval);
				setIntervalState(null);
			}

			window.removeEventListener('resize', setMapCenter);
			const toggle = document.getElementsByClassName('toggle-left')[0];
			toggle && toggle.removeEventListener('click', setMapCenterTimer);
			document.removeEventListener('mouseup', documentMouseUp);
			// clears the dashboardRedirect props if user was redirected to Homepage from other page/popup (View Trip link of Unknown Drivers popup)
			// FWSD-4405
			// if (props.dashboardRedirect) {
			// props.setDashboardRedirect(null)
			// }
		}
	}, []);

	useEffect(() => {
		// debugger
		const { activeTreeItem, markers } = state;
		if (props.showTripsHistory !== prevProps.current.showTripsHistory) {
			// if (props.showTripsHistory) {
			if (props.showTripsHistory && (!dashboardRedirect || (dashboardRedirect.type !== 'redirectFromUnknownDrivers') && !['redirectFromDailyEvents', 'redirectFromBellNotificationsOpenLS'].includes(dashboardRedirect.type))) {
				const obj = markers.find(item => { if (item.device_id === activeTreeItem.device_id) return item })
				setState((prevState) => {
					return {
						...prevState,
						polygons: [],
						markers: [{
							...obj,
							... {
								showInfoWindow: false,
							}
						}]
					}
				})
			} else {
				closeTimeline()
			}
			prevProps.current = props
		}
	}, [props.showTripsHistory]);

	useEffect(() => {
		// debugger
		const { markers } = state;

		if (!_.isEqual(props.selectedTrip, prevProps.current.selectedTrip)) {
			// We have to hide the FleetSidebar when user slects a trip.
			// We have to display the FleetSidebar when user stops the RouteHistory displaying.
			setState((prevState) => ({...prevState,	showFleetSidebar: !props.selectedTrip}))
		}

		if (!_.isEqual(props.tripsGPS, prevProps.current.tripsGPS) || !_.isEqual(props.selectedTrip, prevProps.current.selectedTrip)) {
			if (props.showTripsHistory && props.tripsGPS.length) {
				let pos = props.tripsGPS[0].polygon[0]
				if (pos) {
					// FWSD-4032
					if (props.selectedTrip.isLiveTrip) {
						pos = props.tripsGPS[0].polygon[props.tripsGPS[0].polygon.length - 1]
					}
					const position = { lat: pos.lat, lng: pos.lng }
					markers[0].icon_name = markers[0].entry.vehicle_icon
					markers[0].icon_direction = getIconDirection(markers[0].icon_direction, markers[0].position, position, pos.dir)
					markers[0].position = position;
					markers[0].infoData = Object.assign({}, markers[0].infoData, pos, { driver_name: props.selectedTrip.driver_name })
					markers[0].visible_icon = true;
					markers[0].driver_name = props.selectedTrip.driver_name;

					// console.log('markers: ', markers)

					setState((prevState) => {
						return {
							...prevState,
							markers,
						}
					})
				}
				else {
					setState((prevState) => {
						return {
							...prevState,
							// markers: []
							markers:[{ ...markers[0], visible_icon: false }]
						}
					})
				}
			}
			prevProps.current = props
		}
	}, [props.tripsGPS, props.selectedTrip]);

	useEffect(() => {
		// debugger
		if (!_.isEqual(props.tripTimelineTimestamp, prevProps.current.tripTimelineTimestamp)) {
			if (props.showTripsHistory && props.tripsGPS.length) {
				const pos = _.find(props.tripsGPS[0].polygon, (item) => {
					return moment(item.timestamp) >= moment(props.tripTimelineTimestamp);
				});

				if (pos) {
					const position = { lat: pos.lat, lng: pos.lng };
					const { markers } = state

					markers[0].icon_name = markers[0].entry.vehicle_icon;
					markers[0].icon_direction = getIconDirection(markers[0].icon_direction, markers[0].position, position, pos.dir);
					markers[0].position = position;
					markers[0].infoData = Object.assign({}, markers[0].infoData, pos, { driver_name: props.selectedTrip.driver_name });
					markers[0].visible_icon = true;
					markers[0].driver_name = props.selectedTrip.driver_name;

					setState((prevState) => {
						return {
							...prevState,
							markers
						}
					})
				}
			}
			prevProps.current = props
		}
	}, [props.tripTimelineTimestamp]);

	useEffect(() => {
		// debugger
		if (
			!_.isEqual(props.tripAdvancedPlayerEvent, prevProps.current.tripAdvancedPlayerEvent)
			|| !_.isEqual(props.tripShowShareEvent, prevProps.current.tripShowShareEvent)
			// || !_.isEqual(props.tripsGPSSnapRoad, prevProps.tripsGPSSnapRoad)
			|| !_.isEqual(props.showSnapRoad, prevProps.current.howSnapRoad)
		) { }
	}, [props.tripAdvancedPlayerEvent, props.tripShowShareEvent, props.showSnapRoad, /*props.tripsGPSSnapRoad*/]);

	useEffect(() => {
		if ((!props.location || !props.location.state) && (prevProps.current.company.company.company_id !== props.company.company.company_id)) {
			// setState((prevState) => ({ ...prevState, initDevices: [] }))
			// *The alerts should be "live" where when the alert occurs. The user should never see an alert from hours previous to them logging in to their account.
			traceUserAccess()
			// getVehicleList(() => getDevicesMetadataOnline(), props.company.company.company_id)
			getVehicleList((markers) => {getDevicesMetadataOnline(markers)}, props.company.company.company_id)
			prevProps.current = props
		}
	}, [props.company.company.company_id]);

	useEffect(() => {
		if (state.initDevices.length) {
			// debugger
			devicesGrouping()	
		}	
	}, [state.autoValue, state.selectedGroupFleetPanel, /*state.filtersFleetPanel*/])

	useEffect(() => {
		if (
			dashboardRedirect 
			&& dashboardRedirect.type === 'redirectFromBellNotificationsOpenLS' 
			&& dashboardRedirect.path === '/' 
			&& +props.company.company.company_id === +dashboardRedirect.company_id
		) {
			const obj = _.find(state.markers, ['device_id', dashboardRedirect.device_id.toString()])
			if (obj) {
				setState((prevState) => { return { ...prevState, activeTreeItem: obj.entry, showFleetSidebar: true, showVehicleSidebar: true } })
				openLiveStream(userRole, obj.entry)
				// const el = document.getElementById('liveStreamBtn')
				// if (el) {
				// 	el.click()
				// }
			}
			props.setDashboardRedirect(null)

		}
	}, [dashboardRedirect])


	// useEffect(() => {
	// 	if (state.initDevices.length) {
	// 		debugger
	// 		devicesFiltering()	
	// 	}	
	// }, [state.filtersFleetPanel])

	useInterval(() => {
		// getDevicesEventUpdates(userRole, company_name, partner_name)
		const markers = state.markers
		const company_name = props.company.company.name
		const partner_name = props.company.company.partner
		const markers_list = markers

		getDevicesEventUpdates(userRole,company_name, partner_name)
			.then((res, err) => {
				// debugger
				let mmm = []
				let _alerts = []
				let followmap_position = null

				// const obj28 = res.data.response.devices.find(({vehicle_name}) => vehicle_name === '28')
				// console.log('!!! obj28 general_status, status: ', obj28.general_status, obj28.status)
				// console.log('res.data.response.devices.length: ', res.data.response.devices.length)

				// console.log('!!!!!!!getDevicesEventUpdates res.data.response: ', res.data.response)
				if (/*state.showTripPanel ||*/ props.showTripsHistory) {
					// console.log(' showTripsHistory ')
					const initDevices_list = state.initDevices
					res.data.response.devices.map((item, i) => {
						// let device = _.find(initDevices_list, ['device_id', item.device_id])
						let device = _.find(initDevices_list, (dev) => dev.device_id.toString() === item.device_id.toString())
						if (device) {
							device.online = item.online || device.online
							// device.online_gps   = item.online_gps || device.online_gps
							// device.offline30sec = ( device.online_gps && device.infoData && moment(item.gps.timestamp).diff( moment(device.infoData.timestamp), 'seconds') > 30 )

							mmm.push(device)

							if (item.events && item.events.length) {
								_alerts = _.union(_alerts, fillAlertsArray({ driver_name: device.driver_name, ...item }))
							}
						}

						// FWSD-4032
						if (
							props.selectedTrip
							&& props.selectedTrip.isLiveTrip
							&& item.device_id.toString() === state.activeTreeItem.device_id.toString()
							&& props.tripsGPS.length
						) {
							// Gets the last GPS point (props.tripsGPS[0].polygon) of the Live trip (props.selectedTrip)
							const lastTripsGPSPoint = props.tripsGPS[0].polygon[props.tripsGPS[0].polygon.length - 1]


							// If a trip ends (vehicle idles or goes in parking mode) during a live trip, show the Trip is Processing message
							if (/*!item.online ||*/ !item.ignition.state) {
								// console.log('!!!!!!!!!!! Trip IS Processing')
								// debugger
								props.setSelectedTrip({ ...props.selectedTrip, isLiveTrip: false, isTripProcessing: true })

								const newDeviceTrips = props.deviceTrips.map((devicetrip) => {
									if (devicetrip.route_id === props.selectedTrip.route_id) {
										devicetrip.isLiveTrip = false
										devicetrip.isTripProcessing = true
										devicetrip.end_gps = {
											latitude: (item.gps.lan || lastTripsGPSPoint.lat) * 1,
											longitude: (item.gps.lon || lastTripsGPSPoint.lng) * 1,
											timestamp: item.gps.timestamp,
										}
									}
									return devicetrip
								})
								props.setDeviceTrips(newDeviceTrips)
							}

							// Generates the new last GPS point of the Live trip
							const tripGPSNewPoint = {
								lat: (item.gps.lan || lastTripsGPSPoint.lat) * 1,
								lng: (item.gps.lon || lastTripsGPSPoint.lng) * 1,
								speed: (item.gps.spd || lastTripsGPSPoint.speed) * 1,
								timestamp: moment.utc(item.gps.timestamp).format('YYYY-MM-DD HH:mm:ss'),
								dir: (item.gps.dir || lastTripsGPSPoint.dir) * 1,
								key: ((item.ignition.state || lastTripsGPSPoint.key) * 1).toString(),
								driver_id: lastTripsGPSPoint.driver_id,
								driver_name: lastTripsGPSPoint.driver_name,
								dst: (item.gps.dst || lastTripsGPSPoint.dst) * 1,
							}

							// Stores new last GPS point to the tripsGPS[0].polygon
							props.addTripsGPSPolygonPoint(tripGPSNewPoint)

							// Changes location of the marker on the map
							const item2 = _.find(markers_list, (mark) => mark.device_id.toString() === item.device_id.toString())
							// console.log('item2: ', item2)
							if (item2) {
								item2.entry.online = item.hasOwnProperty('online') ? item.online : item2.entry.online
								if (item.gps) {
									const newGPS = item.gps
									if (_.has(newGPS, 'key')) {
										item2.entry.ignition = { ...item2.entry.ignition, state: newGPS.key }
									} else if (_.has(item.ignition, 'state')) {
										item2.entry.ignition = { ...item2.entry.ignition, state: item.ignition.state }
										newGPS.key = item.ignition.state
									}
									item2.icon_direction = getIconDirection(item2.icon_direction || 'e', item2.position, { lat: newGPS.lan * 1 || 0, lng: newGPS.lon * 1 || 0 }, newGPS.dir)
									item2.position = { lat: newGPS.lan * 1 || item2.position.lat, lng: newGPS.lon * 1 || item2.position.lng }
									item2.infoData = Object.assign({}, item2.infoData, newGPS)
									item2.ignitionСhange = Object.assign({}, item2.ignitionСhange, item.ignition)
								}

								setState((prevState) => { return { 
									...prevState, 
									markers: [item2]
								} })
							}
						}
					})

					const devicestmp = _.uniqWith(_.union(initDevices_list, mmm), _.isEqual)
					const alertstmp = _.uniqWith(_.union(state.alerts, _alerts), _.isEqual)

					setState((prevState) => { return { 
						...prevState, 
						initDevices: devicestmp,
						alerts: alertstmp
					} })
				}
				else {
					let newInitDevices = state.initDevices
					let hasDeviceStatusChanged = false
					res.data.response.devices.map((item, i) => {
						// if (item.vehicle_name === '28') {
						// 	debugger
						// }
						// Updates the initDevices to calculate the Fleet Activity Satus via useEffect
						// TODO - DONE: make sense to call setFleetStatuses here when found any device status changing 
						// instead of calling useEffect (Collects the FLEET ACTIVITY statuses ) each 5 seconds after initDevice storing
						// let hasDeviceStatusChanged = false
						newInitDevices = newInitDevices.reduce((result, initDeviceItem) => {
							if (item.device_id === initDeviceItem.device_id) {
								// initDeviceItem.online = item.online
								// initDeviceItem.ignition = item.ignition
								initDeviceItem = {...initDeviceItem, ...item}

								const newDeviceStatus = detectDeviceStatus(initDeviceItem)
								if (newDeviceStatus !== initDeviceItem.status) {
									hasDeviceStatusChanged = true
								}
								initDeviceItem.status = newDeviceStatus
							}
							result.push(initDeviceItem)
							return result
						}, [])

						// // if was changed any of device we make recalculation the Fleet Activity statuses and devices tree
						// if (hasDeviceStatusChanged) {
						// 	console.log('hasDeviceStatusChanged TRUE')
						// 	const obj28_changed = newInitDevices.find(({vehicle_name}) => vehicle_name === '28')
						// 	console.log('! obj28_changed general_status, status: ', obj28_changed.general_status, obj28_changed.status)

						// 	fleetStatusesCalculation(newInitDevices)
						// 	devicesGrouping(newInitDevices)
						// }

						// Autoupdate online, ignition, driver, status properties of the tree selected device
						if (state.activeTreeItem && state.activeTreeItem.device_id === item.device_id) {
							setState((prevState) => { 
								return { 
									...prevState, 
									activeTreeItem: { 
										...state.activeTreeItem,
										online: item.online,
										ignition: item.ignition,
										driver_id: item.driver_id,
										driver_name: item.driver_name,
										sleep: item.sleep,
										general_status: item.general_status,
										modem_rssi: item.modem_rssi,
										status: detectDeviceStatus({...item, active: state.activeTreeItem.active}) // eventupdates does not return active value
									}
							} })
						}

						// let item1 = _.find(markers_list, ['device_id', item.device_id])
						let item1 = _.find(markers_list, (mark) => mark.device_id.toString() === item.device_id.toString())
						if (item1) {
							item1.entry.online = item.hasOwnProperty('online') ? item.online : item1.entry.online
							// item1.entry.online_gps = item.hasOwnProperty('online_gps') ?  item.online_gps : item1.entry.online_gps
							// item1.entry.offline30sec = false
							if (item.gps) {
								const newGPS = item.gps
								if (_.has(newGPS, 'key')) {
									item1.entry.ignition = { ...item1.entry.ignition, state: newGPS.key }
								} else if (_.has(item.ignition, 'state')) {
									item1.entry.ignition = { ...item1.entry.ignition, state: item.ignition.state }
									newGPS.key = item.ignition.state
								}
								// item1.entry.offline30sec = ( item1.entry.online_gps && item1.infoData && moment(newGPS.timestamp).diff( moment(item1.infoData.timestamp), 'seconds') > 30 )
								item1.icon_direction = getIconDirection(item1.icon_direction || 'e', item1.position, { lat: newGPS.lan * 1 || 0, lng: newGPS.lon * 1 || 0 }, newGPS.dir)
								item1.position = { lat: newGPS.lan * 1 || item1.position.lat, lng: newGPS.lon * 1 || item1.position.lng }
								item1.infoData = Object.assign({}, item1.infoData, newGPS)
								item1.ignitionСhange = Object.assign({}, item1.ignitionСhange, item.ignition)
							}
						}

						mmm = _.union(mmm, [].push(item1))

						if (item.gps && item.gps.lan && item.gps.lon && state.centerDevice && (item.device_id == state.centerDevice.device_id)) {
							followmap_position = { lat: item.gps.lan * 1, lng: item.gps.lon * 1 }
						};
						if (item.events && item.events.length) {
							_alerts = _.union(_alerts, fillAlertsArray({ driver_name: item1.driver_name, ...item }))
						}
					})

					// if was changed any of device we make recalculation the Fleet Activity statuses and devices tree
					if (hasDeviceStatusChanged) {
						// console.log('hasDeviceStatusChanged TRUE')
						// const obj28_changed = newInitDevices.find(({vehicle_name}) => vehicle_name === '28')
						// console.log('! obj28_changed general_status, status: ', obj28_changed.general_status, obj28_changed.status)
						fleetStatusesCalculation(newInitDevices)
						devicesGrouping(newInitDevices)
					}

					const markerstmp = _.uniqWith(_.union(markers_list, mmm), _.isEqual)
					const alertstmp = _.uniqWith(_.union(state.alerts, _alerts), _.isEqual)
					let _state = {
						markers: markerstmp,
						alerts: alertstmp
					}
					if (followmap_position) {
						_state['center'] = { lat: followmap_position.lat, lng: followmap_position.lng }

						setTimeout(() => { google.maps.event.trigger(_map.current.context[MAP], 'resize'); }, 1000)
					}

					setState((prevState) => { return { 
						...prevState, 
						..._state,
						initDevices: newInitDevices,
					} })
				}//* END if (state.showTripPanel)
			})
			.catch((error) => {
				console.log('!getDevicesEventUpdates error: ', error.response, error)
			})
	}, delayInterval)

	const devicesGrouping = (devicesArg, filtersFleetPanelArg) => {
		const filtersFleetPanelList = filtersFleetPanelArg || filtersFleetPanel
		let devicesList = devicesArg || state.initDevices
		// debugger
		// Looks for vehicles in filtered devices list
		if (state.autoValue !== '') {			
			// devicesList = devicesList.filter((device) => ((device.group_name ? device.group_name + ' - ' : '') + (device.vehicle_name ? device.vehicle_name + ' - ' : '') + device.device_id).toLowerCase().includes(state.autoValue))
			devicesList = devicesList.filter((device) => ((device.vehicle_name ? device.vehicle_name + ' - ' : '') + device.device_id).toLowerCase().includes(state.autoValue))
		}

		const groupedDevices = {}
		devicesList.forEach((device) => {
			let deviceGroupName = device.group_name || 'null'
			
			// Filters devices through filtersFleetPanel filters
			if ((Object.keys(filtersFleetPanelList.groups).length && !filtersFleetPanelList.groups[deviceGroupName]) || !filtersFleetPanelList.statuses[device.status]) {
				return
			}

			// Fills the groupedDevices object with list of groups and assigned devices
			if (state.selectedGroupFleetPanel === 'name') {
				const firstChar = (device.vehicle_name || device.device_id).charAt(0)
				deviceGroupName = !isNaN(firstChar) ? '0-9' : firstChar.toUpperCase()
			}
			else if (state.selectedGroupFleetPanel === 'status') {
				deviceGroupName = device.status.charAt(0).toUpperCase() + device.status.slice(1)
			}
			if (groupedDevices[deviceGroupName]) {
				const group = groupedDevices[deviceGroupName]
				group.devices.push(device)
				group.statuses[device.status] ++
				group.value.push(device)
			}
			else {
				const statuses = {
					driving: 0,  sleep: 0, offline: 0, 'pending sleep': 0, awake: 0, parked: 0, disabled: 0, 'pending awake': 0,
				}
				statuses[device.status] = 1

				// checks if current group expanded and set collapsed=false
				const existingGroup = state.groups.find(({groupName}) => groupName === deviceGroupName)

				groupedDevices[deviceGroupName] = { 
					groupName: deviceGroupName,
					devices: [device], 
					collapsed: existingGroup ? existingGroup.collapsed : true,
					statuses,
					id: deviceGroupName,
					value: [device]
				}
			}
		})
	
		const groupNames = _.sortBy(Object.keys(groupedDevices), (group_name) => group_name.toLowerCase())
		const groupedVehiclesResult = groupNames.map((group_name) => (groupedDevices[group_name]))
		// debugger
		setState((prevState) => ({ ...prevState, groups: groupedVehiclesResult}))
	}

	const onSearchChange = (searchValueArg) => {
		const value = searchValueArg.toLowerCase()
		setState((prevState) => ({ ...prevState, autoValue: value }))
		// devicesGrouping()
	}

	const handleMapMounted = (map) => {
		console.log('handleMapMounted map: ', map)
		setMap(map)
	}

	const documentMouseUp = () => {
		// google.maps.event.trigger(_map.context[MAP], 'resize');
		google.maps.event.trigger(_map.current.context[MAP], 'resize');

		document.removeEventListener('mousemove', splitBlocks);
		document.removeEventListener('mouseup', documentMouseUp);

		setState((prevState) => { return { ...prevState, center: { lat: state.center.lat, lng: state.center.lng } } })
	}

	const setMapCenterTimer = () => {
		setTimeout(() => google.maps.event.trigger(_map.current.context[MAP], 'resize'), 200);
	}

	const setMapCenter = (a) => {
		google.maps.event.trigger(_map.current.context[MAP], 'resize');
	}

	const getVehicleList = (callback, company_id) => {
		// debugger
		toggleLoader(true);
		const { location } = props

		if (!company_id) {
			return
		}

		getDevicesList(company_id, 'inactive_devices,ignition,modem_rssi,driver,data_usage')
			.then(res => {
				// debugger
				toggleLoader(false);

				let devices = res.data.response.devices
				let markers = []
		
				// const groupedDevices = {}
				let filtersFleetPanelGroups = [] // collects vehicle group names for  filtersFleetPanel.groups item
				let newMonthlyFleetDataUsageAvailable = 0
				devices.map((item, i) => {
					//* Fills the markers array
					if (dashboardRedirect && dashboardRedirect.device_id) {
						if (+item.device_id === +dashboardRedirect.device_id) {
							markers.push({
								position: { lat: 0, lng: 0 },
								icon_name: item.vehicle_icon,
								icon_direction: 'e',
								active: false,
								name: item.vehicle_name || item.device_id,
								device_id: item.device_id,
								entry: item,
								showInfoWindow: false,
								infoData: null,
								ignitionСhange: null,
								visible_icon: false,
							})
						}
					}
					else {
						markers.push({
							position: { lat: 0, lng: 0 },
							icon_name: item.vehicle_icon,
							icon_direction: 'e',
							active: false,
							name: item.vehicle_name || item.device_id,
							device_id: item.device_id,
							entry: item,
							showInfoWindow: false,
							infoData: null,
							ignitionСhange: null,
							visible_icon: false,
						})
					}

					item.start_tracking = true

					//FWSD-6748, calculates the available data usage for company
					newMonthlyFleetDataUsageAvailable = newMonthlyFleetDataUsageAvailable + getDeviceDataLimit(item.data_limit, props.company.company.company_data)

					// The device statuses detection
					// TODO: API has to return the device status
					item.status = detectDeviceStatus(item)
					
					// Fills the filtersFleetPanelGroups array with list of group names
					const deviceGroupName = item.group_name || 'null'
					if (!filtersFleetPanelGroups.includes(deviceGroupName)) {
						filtersFleetPanelGroups.push(deviceGroupName)
					}

					// // fills the groupedDevices object with list of groups and assigned devices
					// const deviceGroupName = item.group_name || 'null'
					// if (groupedDevices[deviceGroupName]) {
					// 	const group = groupedDevices[deviceGroupName]
					// 	group.devices.push(item)
					// 	group.statuses[item.status] ++, 
					// 	group.value.push(item)
					// }
					// else {
					// 	const statuses = {
					// 		driving: 0,
					// 		sleep: 0,
					// 		offline: 0,
					// 		parked: 0,
					// 		awake: 0,
					// 		disabled: 0,
					// 	}
					// 	statuses[item.status] = 1

					// 	groupedDevices[deviceGroupName] = { 
					// 		groupName: deviceGroupName,
					// 		devices: [item], 
					// 		collapsed: true,
					// 		statuses,
					// 		id: deviceGroupName,
					// 		value: [item]
					// 	}
					// }

					return item
				})
				setMonthlyFleetDataUsageAvailable(newMonthlyFleetDataUsageAvailable  * 1024 * 1024 * 1024)

				// set the filtersFleetPanel.groups list
				filtersFleetPanelGroups = _.sortBy(filtersFleetPanelGroups, (group_name) => group_name.toLowerCase())
				const filtersFleetPanelGroupsResult = filtersFleetPanelGroups.reduce((result, group) => {
					result[group] =  true	
					return result
				}, {})

				const newFiltersFleetPanel = { ...filtersFleetPanel, groups: filtersFleetPanelGroupsResult }

				setState((prevState) => { return { 
					...prevState, 
					initDevices: devices,
					// groups: groupedVehiclesResult,
					markers,
					// collapsedBookkeeping: groupedVehiclesResult.map(() => true),
					alerts: [],
					autoValue: '',
					activeTreeItem: null,
					filtersFleetPanel: newFiltersFleetPanel,
				} })
				// debugger
				// makes grouping of devices according to the Sort By (selectedGroupFleetPanel) value 
				devicesGrouping(devices, newFiltersFleetPanel)
				// makes calculation of the FLEET ACTIVE STATUSES
				fleetStatusesCalculation(devices)


				// If user come from the Edit Vehicle page by the "return to map" button
				if (location && location.state && location.state.type === 'return_to_map') {
					const obj = _.find(markers, ['device_id', location.state.id])
					if (obj) {
						setState((prevState) => { return { 
							...prevState, 
							activeTreeItem: obj.entry,
							showVehicleSidebar: true,
							markers,
						} })

						onTreeDeviceClick(obj.entry)
						browserHistory.replace({ pathname: '/', state: undefined })
					}
				}
				// If user come from the UnknownDrivers popup of Driver page by the "View Trip" link
				// FWSD-4405
				// debugger
				getDashboardRedirect(markers)
				/*else if (location && location.state && location.state.type === 'redirectFromUnknownDrivers') { 
					// const obj = _.find(markers, ['device_id',  location.state.unknownDriver.device_id.toString()])
					// console.log('obj: ', obj)
					// if (obj) {
					// 	obj.showInfoWindow = false
					// 	setState({...state, 
					// 			currentTimelineDateData: location.state.unknownDriver.timestamp+'+00',
					// 			currentTimelineDate: moment(location.state.unknownDriver.timestamp+'+00'),
					// 			moveTo: location.state.unknownDriver.timestamp+'+00',
					// 			activeTreeItem: obj.entry,
					// 			showVehicleSidebar: false,
					// 			markers
					// 		});
					// 			startTripReplay()
					// 		}
					// }
				}*/

				if (typeof callback === 'function') {
					// getDevicesMetadataOnline(markers)
					return callback(markers);
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getDevicesList error: ', error);

				setState({
					...state,
					initDevices: [],
					groups: [],
					markers: [],
					// collapsedBookkeeping: [],
					alerts: [],
					autoValue: '',
					activeTreeItem: null,
				});

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const getIconDirection = (direction_old, pos_old, pos_new, dir) => {
		let direction = direction_old;

		if (dir && dir > 0) {
			if (dir > 337.5 || dir <= 22.5)
				direction = "n";
			else if (dir > 22.5 && dir <= 67.5)
				direction = "ne";
			else if (dir > 67.5 && dir <= 112.5)
				direction = "e";
			else if (dir > 112.5 && dir <= 157.5)
				direction = "se";
			else if (dir > 157.5 && dir <= 202.5)
				direction = "s";
			else if (dir > 202.5 && dir <= 247.5)
				direction = "sw";
			else if (dir > 247.5 && dir <= 292.5)
				direction = "w";
			else if (dir > 292.5 && dir <= 337.5)
				direction = "nw";
		}
		else {
			const pos1 = new google.maps.LatLng(pos_old);
			const pos2 = new google.maps.LatLng(pos_new);
			let heading = google.maps.geometry.spherical.computeHeading(pos1, pos2);

			if (heading == 0 || (pos_old.lat == 0 && pos_old.lng == 0)) {
				return direction_old;
			}

			if (heading > -24 && heading < 23) {
				direction = 'n';
			}
			else if (heading > -68 && heading < -24) {
				direction = 'nw';
			}
			else if (heading > -113 && heading < -69) {
				direction = 'w';
			}
			else if (heading > -158 && heading < -114) {
				direction = 'sw';
			}
			else if (heading > 23 && heading < 69) {
				direction = 'ne';
			}
			else if (heading > 68 && heading < 114) {
				direction = 'e';
			}
			else if (heading > 113 && heading < 158) {
				direction = 'se';
			}
			else if (heading < -159 || heading > 158) {
				direction = 's';
			}
		}

		return direction;
	}

	const fillAlertsArray = (item) => {
		const { alert_gsensor, alert_speed } = state.alert_filters
		let deviceAlerts = []
		if (item.events && item.events.length) {
			item.events.map((alert, i) => {
				if (alert.id && state.alertsRemoved.indexOf(alert.id) == -1) {
					if (['FatalGSensor', 'GSensor'].indexOf(alert.name) > -1 && !alert_gsensor) {
						return false
					}
					else if (alert.name == "HighSpeed" && !alert_speed) {
						return false
					}

					deviceAlerts.push({
						title: convertAlertName(alert.name),
						starttime: alert.starttime,
						endtime: alert.endtime,
						asset: (item.vehicle_name || item.device_id),
						device_id: item.device_id,
						id: alert.id,
						channel: (alert.channel) ? alert.channel : '',
						driver_name: item.driver_name,
					})
				}
			})
		}
		return deviceAlerts
	}

	const setMarkersBound = () => {
		const { user } = props
		const { bounds } = state

		if (bounds && _map) {
			_map.current.fitBounds(bounds)
			_map.current.panToBounds(bounds)
		}
		else {
			setState((prevState) => { return { 
				...prevState,
				zoom: user.user.settings.zoom_level || 6
			} })
		}
	}

	const getDevicesMetadataOnline = (argMarkers=null) => {
		// if (interval) {
		// 	clearInterval(interval)
		// 	setIntervalState(null)
		// }
		if (delayInterval) {
			setDelayInterval(null)
		}

		const { user, company } = props
		// const { markers, activeTreeItem } = state
		// const markers = argMarkers || state.markers
		const company_name = company.company.name
		const partner_name = company.company.partner
		// const markers_list = markers

		if (!company_name || !partner_name) {
			return
		}

		getDevicesLastgps({ user_role: userRole, company_name, partner_name, inactive: true })
			.then((res, err) => {
				let mmm = []
				let _alerts = []
				let bounds = new google.maps.LatLngBounds()
				let boundsArr = []
				// debugger
				const markers_list = argMarkers || state.markers

				// console.log('getDevicesLastgps!!! res.data.response:', res.data.response)
				// console.log('getDevicesLastgps!!! markers_list:', markers_list)
				res.data.response.devices.map((item, i) => {
					const _markers = markers_list.map((item1, i) => {
						if (item1.device_id == item.device_id) {
							item1.driver_name = item.driver_name
							item1.entry.online = item.online
							// item1.entry.online_gps = item.online_gps
							// item1.entry.offline30sec = false
							item1.visible_icon = false

							const newGPS = item.gps
							if (newGPS && newGPS.lan) {
								if (_.has(newGPS, 'key')) {
									item1.entry.ignition = { ...item1.entry.ignition, state: newGPS.key }
								} else if (_.has(item.ignition, 'state')) {
									item1.entry.ignition = { ...item1.entry.ignition, state: item.ignition.state }
									newGPS.key = item.ignition.state
								}
								// item1.entry.offline30sec = ( item.online_gps && item1.infoData && moment(newGPS.timestamp).diff( moment(item1.infoData.timestamp), 'seconds') > 30 )
								item1.icon_direction = getIconDirection(item1.icon_direction || 'e', item1.position, { lat: newGPS.lan * 1 || 0, lng: newGPS.lon * 1 || 0 }, newGPS.dir)
								item1.position = { lat: newGPS.lan * 1 || 0, lng: newGPS.lon * 1 || 0 }
								item1.infoData = Object.assign({}, item1.infoData, newGPS)
								item1.ignitionСhange = Object.assign({}, item1.ignitionСhange, item.ignition)
								item1.visible_icon = true
							} else {
								item1.infoData = ''
								item1.ignitionСhange = ''
								item1.position = { lat: 0, lng: 0 }
							}
						}

						return item1
					})

					mmm = _.union(mmm, _markers)

					if (item.gps && item.gps.lan && item.gps.lon) {
						boundsArr.push({ lat: item.gps.lan * 1 || 0, lng: item.gps.lon * 1 || 0 })
					}
					if (item.events && item.events.length) {
						_alerts = _.union(_alerts, fillAlertsArray(item))
					}
				})

				if (boundsArr.length) {
					boundsArr.forEach((item, index) => {
						bounds.extend(item)
					})
					// debugger
					if (_map && _map.current) {
						_map.current.fitBounds(bounds)
						_map.current.panToBounds(bounds)
					}
				}
				else {
					setState((prevState) => { return { 
						...prevState, 
						zoom: props.user.user.settings.zoom_level || 6
					} })
				}

				const markerstmp = _.uniqWith(_.union(markers_list, mmm), _.isEqual)
				const alertstmp = _.uniqWith(_.union(state.alerts, _alerts), _.isEqual)
				setState((prevState) => { return { 
					...prevState, 
					bounds,
					markers: markerstmp,
					alerts: alertstmp
				} })
			})
			.catch((error) => {
				console.log('!getDevicesLastgps error: ', error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response && error.response.data && error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})


		if (state.company_trace_active) {
			setDelayInterval(5000)
		}
	}

	const onZoomChangedManually = (e, p) => {
		const zoom = _map.current.getZoom()
		setState((prevState) => { return { ...prevState, zoom } })
	}

	const onMaptypeChangedManually = () => {
		setState((prevState) => { return { ...prevState, maptype: _map.current.getMapTypeId() } })
	}

	const onClickAddress = (device_id) => {
		const { markers } = state
		const marker = _.find(markers, ['device_id', device_id])
		const { lat, lng } = marker.position
		marker.infoData.address = 'Loading'
		setState((prevState) => { return { ...prevState, markers } })

		getDeviceAddress({ lat, lng })
			.then((res) => {
				let address = formatAddress(res.data.response.address)
				marker.infoData.address = address || 'Not Available'
				setState((prevState) => { return { ...prevState, markers } })
			})
			.catch(err => {
				console.log(err)
			})
	}

	const onSelectMarker = (device_id) => {
		sendGA({
			category: userRole,
			action: 'Select Device',
			label: 'Map Item Select'
		})
		const { activeTreeItem } = state
		let markerEntry = null
		let selectedDevice
		const markers = state.markers.map((item) => {
			if (item.device_id === device_id) {
				selectedDevice = item
				markerEntry = item.entry
			} else {
				item.showInfoWindow = false
			}

			return item
		})

		if (selectedDevice) {
			if (activeTreeItem && device_id === activeTreeItem.device_id) {
				selectedDevice.showInfoWindow = false
				if (selectedDevice.infoData) {
					selectedDevice.infoData.address = ''
				}

				setState((prevState) => { return { 
					...prevState,
					showVehicleSidebar: false,
					activeTreeItem: null
				} })
			} else {
				if (selectedDevice.infoData) {
					selectedDevice.infoData.address = ''
				}
				// const group_name = state.initDevices.find(device => device.device_id === device_id).group_name
				// const collapsedBookkeeping = state.groups.map(el => el.id === group_name ? false : true);
				const newGroups = state.groups.map((item) => {
					item.collapsed = true
					if (item.groupName === selectedDevice.entry.group_name) {
						item.collapsed = false
					}
					return item
				})

				zoomToVehicle(15, markerEntry)
				setState((prevState) => { return { 
					...prevState,
					showVehicleSidebar: true,
					activeTreeItem: markerEntry,
					groups: newGroups,
					// collapsedBookkeeping 
				} })
			}
		} else {
			setMarkersBound()
		}

		setState((prevState) => { return { 
			...prevState,
			markers
		} })
	}

	const onTreeGroupNodeClick = (treeGroupArg) => {
		const newGroups = state.groups.map((item) => {
			if (item.groupName === treeGroupArg.groupName) {
				item.collapsed = !item.collapsed 
			}
			return item
		})

		setState((prevState) => ({ ...prevState, groups: newGroups }))
	}

	const onTreeDeviceClick = (entry) => {
		sendGA({
			category: userRole,
			action: 'Select Device',
			label: 'Menu Dropdown'
		})
		setShowTripsHistory(false)

		// FWSD-6476 - if the navigation menu panel is extended when the vehicle panel opens, the menu panel should automatically be collapsed 
		// to maximize screen space and not make the window feel cluttered.
		if (!state.activeTreeItem) {
			props.updateSideMenuOpen(false)
		}

		const markers = [...state.markers];
		const selectedDevice = markers.find(vehicle => vehicle.device_id === entry.device_id);
		const { activeTreeItem } = state;
		if (selectedDevice) {
			// DEVICE ALREADY ACTIVE
			if (activeTreeItem && selectedDevice.device_id === activeTreeItem.device_id) {
				if (selectedDevice.infoData) {
					selectedDevice.infoData.address = ''
				}
				setState((prevState) => {
					return {
						...prevState,
						showVehicleSidebar: false,
						activeTreeItem: null
					}
				})
			}
			else {
				if (selectedDevice.infoData) {
					selectedDevice.infoData.address = ''
				}
				if (selectedDevice.position.lat !== 0) {
					zoomToVehicle(15, entry)
					setState((prevState) => {
						return {
							...prevState,
							showVehicleSidebar: true
						}
					})
				}
				else {
					setState((prevState) => {
						return {
							...prevState,
							showVehicleSidebar: true
						}
					})
					toastr.info('Vehicle GPS Unavailable');
				}
				setState((prevState) => {
					return {
						...prevState,
						markers,
						activeTreeItem: entry
					}
				})
			}
		} else {
			setMarkersBound()
			setState((prevState) => {
				return {
					...prevState,
					activeTreeItem: entry
				}
			})
		}

		setState((prevState) => {
			return {
				...prevState,
				markers
			}
		})
	}

	const liveVideoUserAccess = () => {
		const { user } = props
		const userPermission = user.user.company_data

		if (!userPermission.live_active && ['fleet_manager', 'fleet_maintainer', 'group_manager', 'user'].indexOf(userRole) > -1) {
			return false
		}
		else {
			return true
		}
	}

	const traceUserAccess = () => {
		const { user } = props
		const companyPermissions = user.user.company_data

		if (!companyPermissions.trace_active && ['fleet_manager', 'fleet_maintainer', 'group_manager', 'user'].indexOf(userRole) > -1) {
			setState((prevState) => {
				return {
					...prevState,
					company_trace_active: false
				}
			});
		}
		else {
			setState((prevState) => {
				return {
					...prevState,
					company_trace_active: true
				}
			});
		}
	}

	const zoomToVehicle = (zoomSize, entry) => {
		const { activeTreeItem, markers } = state
		if (!entry && !activeTreeItem) {
			toastr.warning('', 'Please select a vehicle to zoom to');
			return;
		}

		const activeDeviceId = (entry || activeTreeItem).device_id;
		const activemarker = markers.filter(marker => marker.device_id == activeDeviceId && marker.visible_icon);

		if (activemarker.length) {
			setState((prevState) => { return { ...prevState, zoom: zoomSize, center: activemarker[0].position } })
		}
	}

	const openClip = (event, eventId) => {
		event.preventDefault();

		const { user, company } = props
		const winOpen = {
			eventId: eventId,
			userRole: user.user.roles[0],
			companyName: company.company.name,
			partnerName: company.company.partner,
			driverRecognition: company.company.driver_recognition,
			user: user.user,
		}
		localStorage.setItem('winOpen', JSON.stringify(winOpen));

		sendGA({
			category: userRole,
			action: 'Alert Popup',
			label: 'Open Alert Popup Clip'
		})

		const width = 1200
		const height = 800
		const w = window
		const d = document
		const e = d.documentElement
		const g = d.getElementsByTagName('body')[0]
		const winWidth = w.innerWidth || e.clientWidth || g.clientWidth
		const winHeight = w.innerHeight || e.clientHeight || g.clientHeight
		const winX = (document.all) ? window.screenLeft : window.screenX
		const winY = (document.all) ? window.screenTop : window.screenY
		const left = ((winWidth - width) / 2) + winX
		const top = ((winHeight - height) / 2) + winY
		const windowSpecs = 'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width=' + width + ',height=' + height + ',left=' + left + ',top=' + top
		const target = eventId
		const win = window.open('/event/video/' + target, target + '1', windowSpecs);

		return win
	}

	const handleSplitBarMouseDown = (e) => {
		e.preventDefault();

		document.addEventListener('mousemove', splitBlocks);
		document.addEventListener('mouseup', documentMouseUp);
	}

	const handleBarToggleMouseDown = (e) => {
		if (e.stopPropagation) {
			e.stopPropagation();
		}

		hideDevicesTree(e)
	}

	const hideDevicesTree = (e) => {
		if ($('#block-tree').width() < 13) {
			$('#block-tree').css("width", 300).css("left", 0);
			$('#map-block').css("left", 300);
		}
		else {
			$('#block-tree').css("width", 12).css("left", 0);
			$('#map-block').css("left", 12);
		}

		setMapCenter();
	}

	const viewEventClip = (e, row) => {
		e.preventDefault();

		//* When sent to storage page from Trip Replay panel, there should be a return tab: https://roscolive.atlassian.net/browse/FWSD-639
		let states = null;
		if (!props.donotuseredux) {
			states = { ...state };
			states.redrawTimeline = true;
			states.popover = {
				show: false,
				left: 0,
				top: 0,
				type: '',
				content: '',
				event: {
					event_type: '',
					event_device_id: 0,
					event_id: 0,
					event_ts: ''
				}
			}
		}
		//* Delete states.alerts;
		props.updateTripreplay(states);

		browserHistory.push({
			pathname: "/event",
			state: {
				event_device_id: row.event_device_id,
				event_id: row.event_id,
				event_ts: row.event_ts,
				type: 'trip'
			}
		});
	}

	const requestCustomAlertVideo = (e, row) => {
		e.preventDefault()

		console.log('requestCustomAlertVideo request', row);

		toggleLoader(true);

		const data = {
			device_id: row.event_device_id,
			timestamp: row.event_ts,
			alert_type: row.event_type
		}
		requestCustomAlertVideo(data)
			.then((res, err) => {
				console.log('requestCustomAlertVideo res', res);

				if (res.data.response.request_id) {
					$(row.img_link).attr('data-event_id', res.data.response.request_id);

					let popover = state.popover;
					popover.event.event_id = res.data.response.request_id;
					setState((prevState) => { return { ...prevState, popover } })
				}
				toggleLoader(false);

				toastr.success('', 'Custom Video Requested');
			})
			.catch((error) => {
				toggleLoader(false);

				console.log('requestCustomAlertVideo error');
				console.log(error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const handleIdentifyDriverClick = (e, event) => {
		e.preventDefault()

		browserHistory.push({
			pathname: "/managedrivers",
			state: {
				event,
				type: 'redirectFromTripReplay'
			}
		});
	}

	const getDevicesMetadataForTrip = (data, callback, getlastknown = false) => {
		toggleLoader(true);

		/* If the vehicle is offline it won't have any replay data at the current time. Instead, we should load and display the last known GPS location. */
		let action = getDevicesMetadataAction;
		if (getlastknown) {
			action = getDevicesMetadataLastKnown;
		}
		action(data)
			.then((res, err) => {
				console.log('!getDevicesMetadataAction/getDevicesMetadataLastKnown res: ', res, err);

				let items = []
				let groups = []
				let lastknowntimestamp = '1970-01-01 00:00:00'
				let k = 0
				const vehicles = _.orderBy(res.data.response, ['vehicle_name', 'device_id'], ['asc', 'asc']);

				vehicles.map((vehicle, i) => {
					let polygons = []
					let polygon = []
					let curgroups = []

					let starttimestamp = null
					let endtimestamp = null
					let curtimestamp = null

					let localtimestamp = null
					let start_ts = null
					let end_ts = null

					vehicle.metadata.map((item, j) => {
						if (item.type === 'Gps') {
							localtimestamp = moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss');
							if (localtimestamp > lastknowntimestamp) {
								lastknowntimestamp = localtimestamp;
							}

							if (!curtimestamp) {
								curtimestamp = localtimestamp; //item.timestamp;
								starttimestamp = localtimestamp; //item.timestamp;
							} else {
								if (moment(item.timestamp).diff(moment(curtimestamp), 'minutes') > 1 && polygon.length > 0) {
									polygons.push({ polygon: polygon, starttimestamp: starttimestamp, endtimestamp: curtimestamp });
									items.push({ group: '' + i, start: moment(starttimestamp), end: moment(curtimestamp), device_id: vehicle.device_id });
									starttimestamp = localtimestamp; //item.timestamp;
									polygon = [];
								}

								curtimestamp = localtimestamp;
							}

							polygon.push({
								"lat": item.latitude,
								"lng": item.longitude,
								speed: item.speed,
								timestamp: localtimestamp,
								dir: item.dir,
								key: item.key,
							});
						} else {
							start_ts = (item.start_ts) ? moment(item.start_ts + '+00').format('YYYY-MM-DD HH:mm:ss') : '';
							end_ts = (item.end_ts) ? moment(item.end_ts + '+00').format('YYYY-MM-DD HH:mm:ss') : '';

							if ((end_ts || start_ts || '1970-01-01 00:00:00') > lastknowntimestamp) {
								lastknowntimestamp = end_ts;
							}

							let img5 = document.createElement('img');
							img5.src = '/images/alert.png';
							img5.style.width = '24px';
							img5.style.height = '24px';
							img5.style.cursor = 'pointer';
							img5.setAttribute('data-event_id', (item.event_id || ''));
							img5.className = `${item.type.toLowerCase()}_${moment(item.start_ts).format('YYYYMMDDHHmmss')}`


							img5.addEventListener('click', function (e) {
								if (typeof e.stopPropagation == "function") {
									e.stopPropagation();
								} else {
									e.cancelBubble = true;
								}
								const content = 'Start alert: ' + ((item.start_ts) ? moment(item.start_ts + '+00').format('YYYY-MM-DD HH:mm:ss') : '') + '<br>End alert: ' + ((item.end_ts) ? moment(item.end_ts + '+00').format('YYYY-MM-DD HH:mm:ss') : '');


								const sideMenu = document.querySelector('.sideMenu')
								const popover = {
									show: true,
									left: e.pageX - e.offsetX - sideMenu.offsetWidth - 25,
									top: e.pageY - e.offsetY - 108,
									type: ((item.type == 'InputSensor' && item.event_id) ? 'DriverEvent' : item.type) + ' (' + (vehicle.vehicle_name || vehicle.device_id) + ')',
									content: content,
									event: {
										img_link: this,
										event_type: item.type,
										event_device_id: vehicle.device_id,
										event_id: $(this).attr('data-event_id') || '',//item.event_id, //7419
										event_ts: item.start_ts || item.end_ts, //"2018-02-18 19:39:40",
									}
								}

								setState((prevState) => { return { ...prevState, popover } })
							});


							if (item.type === 'GSensor') {
								let groupId = i + 'GSensor'
								items.push({ group: groupId, start: moment(start_ts), end: moment(end_ts || start_ts), device_id: vehicle.device_id, content: img5 /*, id: 1*/ });

								if (!curgroups.find(o => o.id === groupId))
									curgroups.push({ id: groupId, content: 'GSensor', type: 'node', device_id: vehicle.device_id });
							} else if (item.type === 'HighSpeed') {
								let groupId = i + 'HighSpeed';
								items.push({ group: groupId, start: moment(start_ts), end: moment(end_ts || start_ts), device_id: vehicle.device_id, content: img5 /*, id: 1*/ });

								if (!curgroups.find(o => o.id === groupId))
									curgroups.push({ id: groupId, content: 'HighSpeed', type: 'node', device_id: vehicle.device_id });
							} else if (item.type === 'InputSensor') {
								let groupId = i + 'InputSensor';
								items.push({ group: groupId, start: moment(start_ts), end: moment(end_ts || start_ts), device_id: vehicle.device_id, content: img5 /*, id: 1*/ });

								if (!curgroups.find(o => o.id === groupId))
									curgroups.push({ id: groupId, content: 'Driver', type: 'node', device_id: vehicle.device_id });
							} else if (item.type === 'FatalGSensor') {
								let groupId = i + 'FatalGSensor';
								items.push({ group: groupId, start: moment(start_ts), end: moment(end_ts || start_ts), device_id: vehicle.device_id, content: img5 /*, id: 1*/ });

								if (!curgroups.find(o => o.id === groupId))
									curgroups.push({ id: groupId, content: 'CriticalGSensor', type: 'node', device_id: vehicle.device_id });
							} else if (item.type === 'UnknownDriver') {
								const groupId = i + 'UnknownDriver';
								items.push({ group: groupId, start: moment(start_ts), end: moment(end_ts || start_ts), device_id: vehicle.device_id, content: img5 /*, id: 1*/ });

								if (!curgroups.find(o => o.id === groupId))
									curgroups.push({ id: groupId, content: 'UnknownDriver', type: 'node', device_id: vehicle.device_id });
							}

						}
					})

					if (polygon.length > 0) {
						polygons.push({ polygon: polygon, starttimestamp: starttimestamp, endtimestamp: curtimestamp });
						items.push({ group: '' + i, start: moment(starttimestamp), end: moment(curtimestamp), device_id: vehicle.device_id });
					}

					const device = state.alertdevices.filter(device => device.device_id == vehicle.device_id)
					const device_entry = (device.length) ? device[0].entry : {}


					let c = i;
					if (i > 9) c = 0;
					if (curgroups.length > 0) {
						groups.push({
							order: k++,
							id: '' + i,
							device_id: vehicle.device_id,
							polygons: polygons,
							content: vehicle.vehicle_name || vehicle.device_id,
							type: 'parent',
							entry: device_entry,
							className: 'parent col' + c,
							driver_name: vehicle.driver_name,
							driver_trips: vehicle.driver_trips,
						});
						curgroups.map((group, j) => {
							group.order = k++;
							group.className = 'node col' + c;
							groups.push(group);
						});
					} else {
						groups.push({
							order: k++,
							id: '' + i,
							device_id: vehicle.device_id,
							polygons: polygons,
							content: vehicle.vehicle_name || vehicle.device_id,
							type: 'parent',
							entry: device_entry,
							className: 'parent col' + c,
							driver_name: vehicle.driver_name,
							driver_trips: vehicle.driver_trips,
						});
					}
				});

				console.log('!!!groups: ', groups);

				let _alertshistory = state.alertshistory;
				_alertshistory.items = items;
				_alertshistory.groups = groups;

				if (getlastknown) {
					console.log('lastknowntimestamp', lastknowntimestamp);
					if (lastknowntimestamp == '1970-01-01 00:00:00') {
						lastknowntimestamp = moment().format('YYYY-MM-DD HH:mm:ss');
					}
					setState((prevState) => { return { 
						...prevState,
						currentTimelineDateData: lastknowntimestamp,
						currentTimelineDate: moment(lastknowntimestamp),
						moveTo: lastknowntimestamp,
						alertshistory: _alertshistory 
					} })
				} else {
					setState((prevState) => { return { ...prevState, alertshistory: _alertshistory } })
				}
				console.log('!!!alertshistory: ', _alertshistory)

				if (callback && typeof callback === "function") {
					callback();
				}

				toggleLoader(false);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log(error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const onClickPlayback = (current) => {
		const { tripsGPS } = props

		if (!moment.isMoment(current)) {
			current = moment(current.time)
		}

		if (tripsGPS.length && tripsGPS[0].polygon.length) {
			const pos = _.find(tripsGPS[0].polygon, (item) => {
				return moment(item.timestamp) >= current;
			});

			if (pos) {
				const position = { lat: pos.lat, lng: pos.lng };
				const { markers } = state

				markers[0].icon_name = markers[0].entry.vehicle_icon;
				markers[0].icon_direction = getIconDirection(markers[0].icon_direction, markers[0].position, position, pos.dir);
				markers[0].position = position;
				markers[0].infoData = Object.assign({}, markers[0].infoData, pos);
				markers[0].visible_icon = true;

				setState((prevState) => {
					return {
						...prevState,
						markers
					}
				})
			} else {
				const { markers } = state

				if (markers.length) {
					markers[0].visible_icon = false;
				}

				setState((prevState) => {
					return {
						...prevState,
						markers
					}
				})
			}
		} else {
			const { markers } = state

			if (markers.length) {
				markers[0].visible_icon = false;
			}

			setState((prevState) => {
				return {
					...prevState,
					markers
				}
			})
		}
	}

	const openAdvancedPlayer = (event) => {
		setState((prevState) => { return { ...prevState, showAdvancedPlayer: true, selectedEvent: event } })
	}

	const closePopover = (e) => {
		e.preventDefault()

		const popover = {
			show: false,
			left: 0,
			top: 0,
			type: '',
			content: '',
			event: {
				event_type: '',
				event_device_id: 0,
				event_id: 0,
				event_ts: ''
			}
		}

		setState((prevState) => { return { ...prevState, popover } })
	}

	const closeTimeline = () => {
		state.alertdevices.map((item, i) => item.entry.start_playback = false);
		setState((prevState) => { return { ...prevState, alertdevices: state.alertdevices } })

		setState((prevState) => { return { 
			...prevState, 
			alertdevices: [],
			alertshistory: [],
			// showTripPanel: false,
			markers: [],
			polygons: [],
			maptype: 'roadmap', //'hybrid',
			tripHeight: -14,
			additionalClassName: 'tripReplayPanelClosed',
		} })

		if (_map?.current) {
			google.maps.event.trigger(_map.current.context[MAP], 'resize');
		}

		//* Start setInterval
		let _markers = [];
		state.initDevices.map((item, i) => {
			//* Fills the markes array
			let name = item.vehicle_name || item.device_id;
			_markers.push({
				position: { lat: 0, lng: 0 },
				icon_name: item.vehicle_icon,
				icon_direction: 'e',
				active: false,
				name: name,
				device_id: item.device_id,
				entry: item,
				showInfoWindow: false,
				infoData: null,
				ignitionСhange: null,
				visible_icon: false
			});

			item.start_tracking = true;
			item.online = false;
			// item.online_gps = false;
			return item;
		});

		setState((prevState) => { return { ...prevState, markers: _markers } })
		getDevicesMetadataOnline(_markers);
	}

	const onClickLiveTraffic = () => {
		const { showLiveTraffic } = state
		setState((prevState) => { return { ...prevState, showLiveTraffic: !showLiveTraffic } })
	}

	const handleVehicleSidebarToggle = () => {
		const { showVehicleSidebar } = state
		setState((prevState) => { return { ...prevState, showVehicleSidebar: !showVehicleSidebar } })
	}

	const onCloseEventVideoAll = () => {
		const { setTripAdvancedPlayerEvent } = props
		setTripAdvancedPlayerEvent(null)
		setState((prevState) => { return { ...prevState, showAdvancedPlayer: false, selectedEvent: null } })
	}

	const onCloseShareEvent = () => {
		const { setTripShowShareEvent } = props
		setTripShowShareEvent(null)
		setState((prevState) => { return { ...prevState, showShareEvent: false } })
	}

	const openShareEvent = (event) => {
		setState((prevState) => { return { ...prevState, showShareEvent: true, selectedEvent: event } })
	}

	const onFlagEvent = (row) => {
		const { id, flagged } = row

		toggleLoader(true);
		if (!flagged) {
			apiCall('PUT', '/events/{id}/flagged', { id })
				.then((res, err) => {
					if (!_.isEmpty(props.tripAdvancedPlayerEvent)) {
						let selectedEvent = props.tripAdvancedPlayerEvent;
						selectedEvent.flagged = true;
						props.setTripAdvancedPlayerEvent(selectedEvent)
					}
					else {
						row.flagged = true
					}
					toggleLoader(false);
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!onFlagEvent put error:', error.response, error)

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		}
		else {
			apiCall('DELETE', '/events/{id}/flagged', { id })
				.then((res, err) => {
					if (!_.isEmpty(props.tripAdvancedPlayerEvent)) {
						let selectedEvent = props.tripAdvancedPlayerEvent;
						selectedEvent.flagged = false;
						props.setTripAdvancedPlayerEvent(selectedEvent)
					}
					else {
						row.flagged = false
					}
					toggleLoader(false);
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!onFlagEvent delete error:', error.response, error)

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				})
		}
	}

	const updateDriver = (event_id, driver) => {
		const updatedEvents = [...state.alerts];
		 updatedEvents.map(event => {
			if (event.id === event_id) {
				event.driver_name = driver.first_name + ' ' + driver.last_name
				event.driver_id = driver.id
				setState((prevState) => { return { ...prevState, selectedEvent: event } })
			}
		})
		setState((prevState) => { return { ...prevState, alerts: updatedEvents } })
	}

	const openEventNote = () => {
		setState((prevState) => { return { ...prevState, showEventNote: true } })
	}

	const onSaveEventNote = (note) => {
		if ((note && note.length) || note === '') {
			if (state.selectedEvent) {
				let selectedEvent = state.selectedEvent;
				selectedEvent.custom_event_type = note;
				setState((prevState) => { return { ...prevState, selectedEvent, showEventNote: false } })
			}
			else if (props.tripAdvancedPlayerEvent) {
				let selectedEvent = props.tripAdvancedPlayerEvent;
				selectedEvent.custom_event_type = note;
				props.setTripAdvancedPlayerEvent(selectedEvent)
				setState((prevState) => { return { ...prevState, showEventNote: false } })
			}
		}
	}

	// FWSD-879
	const onClickSnapRoad = () => {
		const { tripsGPS, setTripsGPSSnapRoad, showSnapRoad, setShowSnapRoad } = props

		// // if user clicks on Snap Road button on the map and request of SnapRoads was done earlier then will not request again
		// if (showSnapRoad === false && tripsGPSSnapRoad.length) {
		// 	setShowSnapRoad(!showSnapRoad)
		// 	return
		// }
		if (showSnapRoad === true) {
			setShowSnapRoad(!showSnapRoad)
			return
		} else {
			setShowSnapRoad(!showSnapRoad)
		}

		if (tripsGPS.length) {
			const GOOGLE_API_KEY = 'AIzaSyCFXM5RPCbe9LzECG88fq9GtKcvnY-ZaDU'
			// const GOOGLE_API_KEY = 'AIzaSyB1aj2I6ZjWPg_79TOrFOWnNJJWLAu77io'
			const snapRoadGPS = []
			tripsGPS.forEach((trip) => {
				const tripGPS = trip.polygon
				const requestArray = []
				let pathValues = []
				let lastDst = 0
				tripGPS.forEach(({ lat, lng, dst }, i) => {
					if (i === 0) {
						lastDst = dst
						pathValues.push(`${lat},${lng}`)
						return
					}

					// filter of points: 
					// 1. coordinates next point should differ from previous
					// 2. distance between points shuld be more 100 meters
					// 3. if next distance less prevous then take this point					
					if (
						(tripGPS[i - 1].lat === lat && tripGPS[i - 1].lng === lng)
						|| (tripGPS[i - 1].dst > lastDst && tripGPS[i - 1].dst - lastDst < 100)
					) {
						return
					}

					lastDst = dst
					pathValues.push(`${lat},${lng}`)
					// google span to roads allows just 100 points per request
					if (pathValues.length === 100) {
						// console.log('pathValues: ', pathValues)
						requestArray.push(axios.get(`https://roads.googleapis.com/v1/snapToRoads?path=${pathValues.join('|')}&interpolate=true&key=${GOOGLE_API_KEY}`))
						pathValues = []
					}
				})
				if (pathValues.length) {
					// console.log('pathValues: ', pathValues)
					requestArray.push(axios.get(`https://roads.googleapis.com/v1/snapToRoads?path=${pathValues.join('|')}&interpolate=true&key=${GOOGLE_API_KEY}`))
				}

				if (requestArray.length) {
					toggleLoader(true);
					Promise.all(requestArray)
						.then(res => {
							console.log('Promise.all res: ', res)
							const snapCoords = []
							res.forEach((snap) => {
								snap.data.snappedPoints.forEach((point) => {
									snapCoords.push({
										lat: point.location.latitude,
										lng: point.location.longitude,
									})
								})
							})
							// console.log('snapCoords: ', snapCoords)
							setTripsGPSSnapRoad([
								{
									polygon: snapCoords,
									starttimestamp: trip.starttimestamp,
									endtimestamp: trip.endtimestamp,
								}
							])

							toggleLoader(false);
						})
						.catch(error => {
							toggleLoader(false);
							console.log('Promise.all error: ', error.response, error)
							toastr.error('Get Snap Road error occurred. Please try again later')
						},
						)

				}
			})
		}
	}

	// FWSD-4405, FWSD-7381
	const getDashboardRedirect = (argMarkers) => {
		const { dashboardRedirect } = props
		const varMarkers = argMarkers || state.markers
		
		if (dashboardRedirect && dashboardRedirect.type) {
			switch (dashboardRedirect.type) {
				case 'redirectFromDailyEvents':
				case 'redirectFromUnknownDrivers':
					const obj = _.find(varMarkers, ['device_id', dashboardRedirect.device_id.toString()])
					if (obj) {
						setShowTripsHistory(true)
						setState((prevState) => { return { ...prevState, activeTreeItem: obj.entry, showVehicleSidebar: true } })
						// onTreeDeviceClick(obj.entry)
						// setShowTripsHistory(true)
					}
					break;
				case 'redirectFromBellNotificationsOpenLS':
					const obj1 = _.find(varMarkers, ['device_id', dashboardRedirect.device_id.toString()])
					if (obj1) {
						setState((prevState) => { return { ...prevState, activeTreeItem: obj1.entry, showFleetSidebar: true, showVehicleSidebar: true } })
						openLiveStream(userRole, obj1.entry)
						// const el = document.getElementById('liveStreamBtn')
						// if (el) {
						// 	el.click()
						// }
					}
					props.setDashboardRedirect(null)
					break;
				default:
					break;
			}
		}
	}

	const { activeTreeItem, centerDevice, initDevices, showVehicleSidebar, showFleetSidebar, /*showTripPanel,*/ showEventVideoAll, selectedGroupFleetPanel, filtersFleetPanel } = state;

	if (!user) {
		return (<div></div>)
	}

	const setFiltersFleetPanel = (fultersArg) => {
		setState((prevState) => ({ ...prevState, filtersFleetPanel: fultersArg }))
		devicesGrouping()
	}

	// Collects the FLEET ACTIVITY statuses 
	const fleetStatusesCalculation = (initDevicesArg) => {
		const initDevices = initDevicesArg || state.initDevices
		const initStatuses = { driving: 0,  sleep: 0, offline: 0, 'pending sleep': 0, awake: 0, parked: 0,  disabled: 0, 'pending awake': 0, }

		const newFleetStatuses = initDevices.reduce((result, device) => {
			if (device.status === 'pending sleep') {
				result['sleep'] =  (result['sleep'] || 0 ) + 1
			}
			else if (device.status === 'pending awake') {
				result['awake'] =  (result['awake'] || 0 ) + 1
			}
			else {
				result[device.status] =  (result[device.status] || 0 ) + 1
			}
			return result
		}, {})

		setFleetStatuses({ ...initStatuses, ...newFleetStatuses})
	}

	const renderFleetActivityStatus = (typeArg) => {
		const deviceStatusesActivityPanel = {
			'recording': ['driving', 'parked', 'awake' /*, 'pending awake'*/],
			'non-recording': ['sleep', 'offline', 'disabled' /*, 'pending sleep'*/],
		}
		
		const res = []
		const fleetStatusesKeys = getAvailableFleetStatuses(Object.keys(fleetStatuses)).filter(value => deviceStatusesActivityPanel[typeArg].includes(value))

		fleetStatusesKeys.forEach((status_name) => {
			res.push(
				<div key={status_name} className={`status-row ${typeArg} ${fleetStatusesKeys.length >= 3 ? 'full-access' : ''}`}>
					<div className={`icon ${status_name.replace(' ', '_')}`} />
					<div>
					<InfoPop 
						title={`Activity Status - ${status_name.charAt(0).toUpperCase() + status_name.slice(1)}`} 
						body={deviceStatusPopups[status_name]}
					>
						<span className="title">{getDeviceStatusName(status_name)}</span>
					</InfoPop>
					</div>
					{fleetStatuses[status_name]}
				</div>
			)
		})

		return res
	}

	const getActiveMarkerInfo = (device_id) => {
		// console.log('getActiveMarkerInfo device_id: ', device_id)
		// console.log('getActiveMarkerInfo activeTreeItem: ', activeTreeItem)
		if (activeTreeItem && activeTreeItem.device_id === device_id) {
			let markerInfo = state.markers.find(marker => marker.device_id === device_id)
			if (markerInfo) {
				markerInfo.speed_unit = props.user.user.speed_unit
			}
			// console.log('getActiveMarkerInfo state.markers: ', state.markers)
			// console.log('getActiveMarkerInfo markerInfo: ', markerInfo)
			return markerInfo
		}
		else {
			return null
		}
	}

	const setPropStates = (states, callback = null) => {
		setState({ ...state, ...states })
		if (typeof callback === 'function') {
			callback();
		}
	}

	// const treeHeaderHeight = document.getElementById('tree-header') && document.getElementById('tree-header').offsetHeight + 72
	// console.log(state, props);
	// console.log('STATE Dashboard: ', state);
	// console.log('Dashboard _map: ', _map);

	// console.log('PROPS Dashboard: ', props)
	// console.log('STATE Dashboard: ', state)

	return (
		<div>
			<div className="tracking-content" style={{ position: 'relative' }} >
				<GreyHeader title='Home' subtitle={props.company.company.name} />
				<main className={`gps-tracking show-fleet-pannel show-vehicle-pannel showVehicleSidebar`}
					style={{ position: 'relative', clear: 'both' }}
				>
					<div className="track-container-wrap">
					<div className="track-container-inner" style={{ display: 'flex', position: 'relative', height: 'calc(100vh - 68px)' }}>
						<div style={{ display: 'flex', position: 'relative', }}>
							{showFleetSidebar &&
								<div id="block-tree" style={{ float: 'left', position: 'relative', height: 'calc(100vh - 68px)', width: '300px'}}>
									
										<div className='vehicle-list-wrapper'>
											<div className='fleet-activity-status'>
												<div className="header">RECORDING ACTIVITY STATUS {/* <LabelTag text="Beta" tag_type="beta" />*/}</div>
												<div className="body">
													{ renderFleetActivityStatus('recording') }
												</div>
												<div className="header">NON-RECORDING ACTIVITY STATUS</div>
												<div className="body">
													{ renderFleetActivityStatus('non-recording') }
												</div>
											</div>

											<SearchBar
												placeholder='Search Vehicle...'
												term={state.autoValue}
												onSearch={(e) => onSearchChange(e.target.value)}
											/>

											<div className="vehicles-sort-wrapper">
												<h5>Sort By</h5>
												<div className="vehicles-sort-filter">
													<select
														placeholder="select"
														className="rosco-select block"
														name="groups"
														value={selectedGroupFleetPanel}
														onChange={(e) => setState((prevState) => ({ ...prevState, selectedGroupFleetPanel: e.target.value }))}
													>	
														<option value="group">Group</option>
														<option value="name">Name</option>
														<option value="status">Status</option>
													</select>

													<TreeVehiclesFilter
														filters={getAvailableFleetStatuses(filtersFleetPanel, 'filters')}
														setFiltersFleetPanel={setFiltersFleetPanel}
													/>
												</div>
											</div>

											<TreeVehicles 
												deviceGroups={state.groups}
												// collapsedBookkeeping ={state.collapsedBookkeeping}
												activeTreeItem ={state.activeTreeItem}
												showTripsHistory={showTripsHistory}
												userRole={userRole}
												markers={state.markers}
												// onTreeItemClick={handleTreeClick}
												onTreeDeviceClick={onTreeDeviceClick}
												onTreeGroupNodeClick={onTreeGroupNodeClick}
											/>
										</div>
								</div>
							}

							{showVehicleSidebar && activeTreeItem &&
								<div className="vehicle-sidebar">
									{showVehicleSidebar && activeTreeItem
										&& (
											<VehicleSidebar
												activeTreeItem={activeTreeItem}
												markerInfo={getActiveMarkerInfo(activeTreeItem.device_id)}
												monthlyFleetDataUsageAvailable = {monthlyFleetDataUsageAvailable}
												companyDevicesCount={state.initDevices.length}
												_map={_map}
												onClickPlayback={onClickPlayback}
												openAdvancedPlayer={openAdvancedPlayer}
											/>
										)}
								</div>
							}
							

							{/* Vehicle right sidebar */}
							<button
								style={{ left: '100%' }}
								// className={`vehicle-sidebar-toggle ${!activeTreeItem ? 'disabled' : ''}`}
								className="vehicle-sidebar-toggle"
								title={`${!activeTreeItem ? 'Select a vehicle first' : ''}`}
								// disabled={!activeTreeItem}
								// onClick={handleVehicleSidebarToggle}
								onClick={() => {
									if (!showFleetSidebar) {
										const newState = { showFleetSidebar: true }
										if (activeTreeItem) {
											newState.showVehicleSidebar = true
										}

										setState((prevState) => ({ ...prevState, ...newState }))
									}
									else if (showVehicleSidebar) {
										setState((prevState) => ({ ...prevState, showVehicleSidebar: false }))
									}
									else if (showFleetSidebar) {
										setState((prevState) => ({ ...prevState, showFleetSidebar: false }))
									}
									// else {
									// 	const newState = { showFleetSidebar: true }
									// 	if (activeTreeItem) {
									// 		newState.showVehicleSidebar = true
									// 	}

									// 	setState((prevState) => ({ ...prevState, ...newState }))
									// }
								}}
							>
								{ !showFleetSidebar
									? <FaTruckMoving />
									: <IoIosArrowBack color='#ED1E79' />
								}
							</button>
						</div>
						<div id="map-block" className={classnames(state.additionalClassName, { "hybrid": state.maptype == 'hybrid' })} style={{ width: '100%', position: 'initial' }}>
							<div className="row1" style={{ marginRight: 0, position: 'relative' }}>
								<SimpleMapExampleGoogleMap
									userRole={userRole}
									containerElement={
										(isMobileOnly) ?
											<div style={{ height: `calc(100vh - 50px)` }} className='container-element' />
											:
											<div style={{ height: `calc(100vh - ${state.tripHeight}px - 82px)`, }} className='container-element' />
									}
									mapElement={
										<div style={{ height: `calc(100vh - 68px)` }} className='map-element' />
									}
									markers={state.markers}
									center={state.center}
									zoom={state.zoom}
									maptype={state.maptype}
									speed_unit={props.user.user.speed_unit}
									liveVideoUserAccess={liveVideoUserAccess()}
									polygons={state.polygons}
									snapRoadGPS={state.snapRoadGPS}
									// singleTrucks={state.singleTrucks}
									// showTripPanel={showTripPanel}
									onMapMounted={handleMapMounted}
									onSelectMarker={onSelectMarker}
									onClickAddress={onClickAddress}
									// onOpenLive      = {openLive}
									onZoomChanged={onZoomChangedManually}
									onMapTypeIdChanged={onMaptypeChangedManually}
									_map={_map}
									setMap={setMap}
									showLiveTraffic={state.showLiveTraffic}
									onClickLiveTraffic={onClickLiveTraffic}
									onClickSnapRoad={onClickSnapRoad}
									openClip={openClip}
									activeTreeItem={activeTreeItem}
								/>

								{/* Displays the Trip Snapshot Carousel --FWSD-4740 */}
								{selectedTrip && <TripSnapshots activeTreeItem={activeTreeItem} />}

								{(!state.company_trace_active) ? <div className="gray-map"></div> : ''}
							</div>
						</div> {/* END id="map-block" */}
					</div>
					</div>
				</main> {/* END className='gps-tracking' */}



				{state.popover.show
					&& (
						<div id="popover-basic" role="tooltip" className="popover top" style={{ left: state.popover.left, top: state.popover.top }}>
							<div>
								<h3 className="popover-title">
									{state.popover.type}
								</h3>
								<a href="/" className="alert-remove" style={{ position: 'absolute', top: '2px', right: '3px' }} onClick={closePopover}>
									<span className="glyphicon glyphicon-remove"></span>
								</a>
							</div>
							<div className="popover-content">
								<div dangerouslySetInnerHTML={{ __html: state.popover.content }}></div>
								<div>
									{state.popover.event.event_id
										? (
											<a href="/" onClick={e => viewEventClip(e, state.popover.event)}>
												Event Clips (id={state.popover.event.event_id})
											</a>
										)
										: (
											(userRole !== 'sales_demo')
												? <a href='/' onClick={e => requestCustomAlertVideo(e, state.popover.event)}>Request Video</a>
												: <a href='javascript:void(0)' className='disabled'>Request Video</a>
										)
									}
									{state.popover.event.event_type === "UnknownDriver"
										&& (
											<div>
												<a href='/' onClick={(e) => handleIdentifyDriverClick(e, state.popover.event)}>Identify Driver</a>
											</div>
										)}
								</div>
							</div>
						</div>
					)}
			</div>

			{((!_.isEmpty(tripAdvancedPlayerEvent) && tripAdvancedPlayerEvent.uploading_status === 'VIDEO_UPLOADED') || state.showAdvancedPlayer)
				&& (
					<AdvancedPlayer
						event={state.selectedEvent || tripAdvancedPlayerEvent}
						onHide={onCloseEventVideoAll}
						openShareEvent={openShareEvent}
						onFlagEvent={onFlagEvent}
						isFlagged={(state.selectedEvent && state.selectedEvent.flagged) || (tripAdvancedPlayerEvent && tripAdvancedPlayerEvent.flagged)}
						openEventNote={openEventNote}
						note={(state.selectedEvent && state.selectedEvent.custom_event_type) || (tripAdvancedPlayerEvent && tripAdvancedPlayerEvent.custom_event_type)}
						updateDriver={updateDriver}
					/>
				)}

			{(!_.isEmpty(tripAdvancedPlayerEvent) && tripAdvancedPlayerEvent.uploading_status !== 'VIDEO_UPLOADED')
				&& (
					<EventViewSnapshotModal
						event={tripAdvancedPlayerEvent}
						onHide={() => props.setTripAdvancedPlayerEvent(null)}
					/>
				)}

			{(!_.isEmpty(tripShowShareEvent) || state.showShareEvent)
				&& (
					<EventShareModal
						event={state.selectedEvent || tripShowShareEvent}
						onHide={onCloseShareEvent}
					/>
				)}

			{state.showEventNote &&
				<EventNoteModal
					show={state.showEventNote}
					event={state.selectedEvent || tripAdvancedPlayerEvent}
					onHide={() => setState({ ...state, showEventNote: false })}
					company_name={props.company.company.name || user.company_name}
					partner_name={props.company.company.partner || user.partner_company_name}
					onSave={(note) => onSaveEventNote(note)}
				/>
			}
		</div>
	)
}

const mapStateToProps = ({ user, company, tripreplay, dashboardData, partnerList }) => ({
	user,
	company,
	tripreplay,
	showTripsHistory: dashboardData.showTripsHistory,
	tripsGPS: dashboardData.tripsGPS,
	// tripsGPSSnapRoad: dashboardData.tripsGPSSnapRoad,
	selectedTrip: dashboardData.selectedTrip,
	tripTimelineTimestamp: dashboardData.tripTimelineTimestamp,
	tripAdvancedPlayerEvent: dashboardData.tripAdvancedPlayerEvent,
	tripShowShareEvent: dashboardData.tripShowShareEvent,
	showSnapRoad: dashboardData.showSnapRoad,
	dashboardRedirect: dashboardData.dashboardRedirect,
	deviceTrips: dashboardData.deviceTrips,
	sideMenuOpen: dashboardData.sideMenuOpen,
	companiesCollection: partnerList.company_collection,
});

const mapDispatchToProps = dispatch => ({
	updateTripreplay: (tripreplay) => {
		dispatch({ type: 'GET_TRIPREPLAY_SUCCESS', payload: tripreplay });
	},
	setTripAdvancedPlayerEvent: (state) => {
		dispatch({ type: 'SET_TRIP_ADVANCED_PLAYER_EVENT', payload: state })
	},
	setTripShowShareEvent: (state) => {
		dispatch({ type: 'SET_TRIP_SHOW_SHARE_EVENT', payload: state })
	},
	setTripsGPSSnapRoad: (state) => {
		dispatch({ type: 'SET_TRIPS_GPS_SNAP_ROAD', payload: state })
	},
	setShowSnapRoad: (state) => {
		dispatch({ type: 'SET_SHOW_SNAP_ROAD', payload: state })
	},
	setShowTripsHistory: (state) => {
		dispatch({ type: 'SET_SHOW_TRIP_HISTORY', payload: state })
	},
	addTripsGPSPolygonPoint: (state) => {
		dispatch({ type: 'ADD_TRIPS_GPS_POLYGON_POINT', payload: state })
	},
	setSelectedTrip: (state) => {
		dispatch({ type: 'SET_SELECTED_TRIP', payload: state })
	},
	setDeviceTrips: (arr) => {
		dispatch({ type: 'SET_DEVICE_TRIPS', payload: arr })
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	},
	updateSideMenuOpen: (user) => {
		dispatch({ type: 'SET_SIDE_MENU_OPEN', payload: user });
	},
	setDashboardRedirect: (state) => {
		dispatch({ type: 'SET_DASHBOARD_REDIRECT', payload: state })
	},
	updateCompany: (company) => {
		dispatch({ type: 'GET_COMPANY_SUCCESS', payload: company });
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
