import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Dropdown } from 'react-bootstrap'
import { MAP } from 'react-google-maps/lib/constants'

import { RiAlertFill } from 'react-icons/ri'

import AvailableDatesNav from '../DailyEvents/AvailableDatesNav'
import TripReplayControls from './TripReplayControls'
import SpeedEventsDropdown from './SpeedEventsDropdown'

import { vehicleSidebarIcons } from '../../../component/helpers/iconHelpers'
import momenttz from '../../../component/helpers/momenttz'
import { formatAddress } from '../../../component/helpers/tablesFuncHelpers'
import { apiCallGet } from '../../../action/RouterActions'

import './TripsGrid.sass'

const TripsGrid = (props) => {
	const { user, eventDate, activeTreeItem, selectedTrip, setPropStates, onClickPlayback, deviceTrips, toggleLoader, dashboardRedirect, setSelectedTrip, setTripsGPS, setShowTripsHistory, setSelectedDropdownEventId, tripsGPS } = props;

	const prevPropsActiveTreeItemRef = useRef(props.activeTreeItem);

	const [availableDates, setAvailableDates] = useState([]);
	const [selectedTripState, setSelectedTripState] = useState('');

	useEffect(() => {
		// debugger
		// console.log('TropsGrid useEffect componentDidMount props: ', props)
		// const { dashboardRedirect, setPropStates, setDashboardRedirect } = props
		getAvailableDates()
		// getAvailableDates(() => {
		// 	if (availableDates.length) {
		// 		// FWSD-4405
		// 		if (dashboardRedirect && ['redirectFromUnknownDrivers', 'redirectFromDailyEvents'].includes(dashboardRedirect.type)) {
		// 			const newEventDate = dashboardRedirect.timestamp
		// 			setPropStates({ eventDate: newEventDate })
		// 			setDashboardRedirect(null) // clears the dashboardRedirect redux props
		// 			getDeviceTrips(
		// 				1, 10, true, newEventDate,
		// 				() => {
		// 					// handleTripCardClick(deviceTrips[0])
		// 					handleTripCardClick(findTripByTime(newEventDate))
		// 				},
		// 			)
		// 		}
		// 		else {
		// 			setPropStates({ eventDate: availableDates[availableDates.length - 1] })
		// 			getDeviceTrips(1, 10, true, availableDates[availableDates.length - 1])
		// 		}
		// 	} else {
		// 		toggleLoader(false);
		// 	}
		// })

		return () => {
			// debugger
			// setSelectedTrip(null)
			// setTripsGPS([])
			// setShowTripsHistory(false)
			// setSelectedDropdownEventId(null)
			// if (selectedTrip) {
			setSelectedTrip(null)
			// }
			// if (tripsGPS.length) {
			setTripsGPS([])
			// }
			setShowTripsHistory(false)
			setSelectedDropdownEventId(null)
		}
	}, [])

	useEffect(() => {
		// debugger
		if (availableDates.length) {
			// FWSD-4405
			if (dashboardRedirect && ['redirectFromUnknownDrivers', 'redirectFromDailyEvents'].includes(dashboardRedirect.type)) {
				const newEventDate = dashboardRedirect.timestamp
				setPropStates({ eventDate: momenttz(newEventDate + '+00') })
				props.setDashboardRedirect(null) // clears the dashboardRedirect redux props
				getDeviceTrips(
					// 1, 10, true, newEventDate,
					1, 10, true, momenttz(newEventDate + '+00'),
					(deviceTrips) => {
						// handleTripCardClick(deviceTrips[0])
						handleTripCardClick(findTripByTime(newEventDate, deviceTrips))
					},
				)
			}
			else {
				setPropStates({ eventDate: availableDates[availableDates.length - 1] })
				getDeviceTrips(1, 10, true, availableDates[availableDates.length - 1])
			}
		}
		// }, [availableDates.length])
	}, [availableDates])

	useEffect(() => {
		// debugger
		if (prevPropsActiveTreeItemRef.current.device_id !== activeTreeItem.device_id) {
			setShowTripsHistory(false)
		}
		prevPropsActiveTreeItemRef.current = activeTreeItem
		// getAvailableDates(() => {
		// 	if (availableDates.length) {
		// 			setEventDate(availableDates[availableDates.length - 1]);
		// 			getFleetEvents();
		// 	} else {
		// 		toggleLoader(false);
		// 		// setEvents([]);
		// 		toastr.warning('', 'Device does not have events')
		// 	}
		// },
	}, [activeTreeItem.device_id])

	const getAvailableDates = (callback) => {
		const tz = moment().utcOffset();
		const timezone = 'UTC' + ((tz > 0) ? '+' : '') + tz / 60
		const params = {
			deviceId: activeTreeItem.device_id,
			start_ts: '20150101000000',
			end_ts: moment.utc().format('YYYYMMDDHHmmss'),
			timezone,
		};
		apiCallGet('/devices/{deviceId}/routes/dates', params)
			.then((res, err) => {
				// console.log('!getAvailableDates res: ', res, err);
				setAvailableDates(res.data.response.events_dates);

				if (callback && typeof callback === 'function') {
					callback();
				}
			})
			.catch((error) => {
				console.log('!getAvailableDates error: ', error.response, error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const getDeviceTrips = (page = 1, sizePerPage = 10, showLoader = true, argEventDate = null, callback = null) => {
		// console.log('!!!getDeviceTrips argEventDate: ', argEventDate)
		// debugger
		const { eventDate, activeTreeItem, setDeviceTrips } = props
		const paramEventDate = argEventDate || eventDate
		// console.log('paramEventDate: ', paramEventDate)
		toggleLoader(true)
		const params = {
			deviceId: activeTreeItem.device_id,
			start_ts: momenttz(moment(paramEventDate).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
			end_ts: momenttz(moment(paramEventDate).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
		}
		apiCallGet('/devices/{deviceId}/routes', params)
			.then((res, err) => {
				// console.log('!getDeviceTrips res: ', res, err)

				const { routes } = res.data.response
				const routesSorted = routes.sort((a, b) => ((!a.end_gps || !a.end_gps.timestamp || (a.end_gps.timestamp > b.end_gps.timestamp)) ? -1 : 1))
				const newDeviceTrips = routesSorted.map((item) => {
					if (!item.end_gps) {
						item.end_gps = { timestamp: momenttz().utc().format('YYYY-MM-DD HH:mm:ss') }
						item.duration = moment.utc().diff(moment.utc(item.start_gps.timestamp), 'seconds')
						item.isLiveTrip = true
					} else if (!item.end_gps.timestamp) {
						item.end_gps.timestamp = momenttz().utc().format('YYYY-MM-DD HH:mm:ss')
						item.duration = moment.utc().diff(moment.utc(item.start_gps.timestamp), 'seconds')
						item.isLiveTrip = true
					}
					return item
				})
				// setDeviceTrips(deviceTrips)
				// 	if (typeof callback === 'function') {
				// 		callback()
				// 	}
				setDeviceTrips(newDeviceTrips)
				if (typeof callback === 'function') {
					callback(newDeviceTrips)
				}

				toggleLoader(false);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getDeviceTrips error: ', error.response, error)

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const handleTripCardClick = (argTrip) => {
		// console.log('TripsGrid handleTripCardClick argTrip: ', argTrip)
		// console.log('handleTripCardClick selectedTrip: ', selectedTrip)
		// debugger
		const { _map, activeTreeItem, setTripsGPS, setSelectedTrip } = props

		if (!argTrip || selectedTripState === argTrip.route_id) {
			return
		}
		setSelectedTripState(argTrip.route_id)
		setSelectedTrip(argTrip)

		// devices/metadata?start_ts=20210126203426&end_ts=20210127203426&ids=43562297
		const params = {
			ids: activeTreeItem.device_id,
			start_ts: momenttz(`${argTrip.start_gps.timestamp}+00`).utc().format('YYYYMMDDHHmmss'),
			end_ts: momenttz(`${argTrip.end_gps.timestamp}+00`).utc().format('YYYYMMDDHHmmss'),
		}
		toggleLoader(true)
		apiCallGet('/devices/metadata', params)
			.then((res, err) => {
				// console.log('!handleTripCardClick res: ', res, err)

				const polygons = []
				const polygon = []
				const { response } = res.data
				response.map((vehicle) => {
					vehicle.metadata.map((item, index) => {
						if (item.latitude) {
							polygon.push({
								lat: item.latitude,
								lng: item.longitude,
								speed: item.speed,
								// timestamp: momenttz(item.timestamp).format('YYYY-MM-DD HH:mm:ss+00'),
								timestamp: moment.utc(item.timestamp).format('YYYY-MM-DD HH:mm:ss+00'),
								dir: item.dir,
								key: item.key,
								driver_id: index,
								driver_name: `deiver ${index}`,
								dst: item.dst,
							})
						}
					})
				})

				polygons.push({
					polygon,
					// starttimestamp: momenttz(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
					// starttimestamp: momenttz('2021-01-28 17:00:00+00').format('YYYY-MM-DD HH:mm:ss'),
					// starttimestamp: momenttz(`${argTrip.start_gps.timestamp}+00`).format('YYYY-MM-DD HH:mm:ss'),
					starttimestamp: argTrip.start_gps.timestamp,
					// endtimestamp: momenttz(item.timestamp).format('YYYY-MM-DD HH:mm:ss')
					// endtimestamp: momenttz('2021-01-28 20:00:00+00').format('YYYY-MM-DD HH:mm:ss')
					// endtimestamp: momenttz(`${argTrip.end_gps.timestamp}+00`).format('YYYY-MM-DD HH:mm:ss'),
					endtimestamp: argTrip.end_gps.timestamp,
				});

				setTripsGPS(polygons);

				// map zooming: the map should adjust it's zoom level to encompass the entire trip
				const bounds = new google.maps.LatLngBounds()
				polygon.forEach((item) => {
					bounds.extend({ lat: item.lat * 1 || 0, lng: item.lng * 1 || 0 })
				})
				if (polygon.length) {
					_map.current.fitBounds(bounds)
					_map.current.panToBounds(bounds)
				}

				// Hides the devices tree --FWSD-4740
				// if ($('#block-tree').width() > 12) {
				// 	$('#block-tree').css('width', 12).css('left', 0);
				// 	$('#map-block').css('left', 12);
				// }

				toggleLoader(false);
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!handleTripCardClick error:', error.response, error)

				toastr.error('There was an issue loading the selected trip. Please try again later.')
			})
	}

	const handleAlertDropdownClick = (event) => {
		const { _map, tripsEvents, setSelectedDropdownEventId, setTripTimelineTimestamp } = props
		// console.log('handleAlertDropdownClick _map: ', _map)
		// console.log('handleAlertDropdownClick event: ', event)
		console.log('handleAlertDropdownClick tripsEvents: ', tripsEvents)

		const obj = tripsEvents.find(({ id }) => id * 1 === event.id * 1)
		if (obj) {
			_map.current.panTo(obj.position)
			_map.current.context[MAP].setZoom(18)
			setSelectedDropdownEventId(event.id)
			setTripTimelineTimestamp(momenttz(`${event.timestamp + '+00'}`).format('YYYY-MM-DD HH:mm:ss'))
		}
		// _map.panTo({lat:0, lng:0})
		// _map.context[MAP].setZoom(17)
	}

	// const findTripByTime = (argTimestamp) => {
	const findTripByTime = (argTimestamp, argDeviceTrips = null) => {
		const varDeviceTrips = argDeviceTrips || deviceTrips
		// const obj = deviceTrips.find((trip) => trip.start_gps.timestamp <= argTimestamp)
		const obj = varDeviceTrips.find((trip) => trip.start_gps.timestamp <= argTimestamp)

		if (obj) {
			return obj
		}
		// return deviceTrips[deviceTrips.length - 1]
		return varDeviceTrips[varDeviceTrips.length - 1]
	}

	// console.log('PROPS TripsGrid: ', props)
	// console.log('STATE TripsGrid availableDates, selectedTripState: ', availableDates, selectedTripState)

	return (
		<div className="tripsGridWrapper">
			<div className="date date-container-mobile">
				<AvailableDatesNav
					eventDate={eventDate}
					availableDates={availableDates}
					sizePerPage={1}
					setPropStates={setPropStates}
					getFleetEvents={getDeviceTrips}
					isTripHistory
					clearTimer={() => { }}
				/>
			</div>

			<div className="separator" />

			<div className="tripCardsWrapper">
				{deviceTrips.length ?
					deviceTrips.map((item, index) => {
						const liveTrip = item.isLiveTrip // FWSD-3740

						return (
							<div
								className={`
										tripCard
										${!_.isEmpty(selectedTrip) && selectedTrip.start_gps.timestamp === item.start_gps.timestamp ? 'active' : ''}
										${liveTrip ? 'liveTrip' : ''}
								`}
								key={item.route_id}
								role="button"
								tabIndex="0"
								onClick={() => handleTripCardClick(item)}
							>
								{liveTrip ? (
									<div className="tripEnd">
										<b>Live Trip</b>
									</div>
								) : (
									<div className="tripEnd">
										<div className="iconWrapper">
											<img src="/images/ico/marker_stop.svg" alt="Trip Stop" />
										</div>
										<div className="address">
											{formatAddress(item.end_address)}
										</div>
										<div className="timestamp">
											{momenttz(`${item.end_gps.timestamp}+00`).format('h:mm a')}
										</div>
									</div>
								)}
								<div className="tripDuration">
									{liveTrip ? (
										<div className="content" style={{ paddingLeft: 0 }}>
											<span style={{ marginRight: '20px' }}>
												{moment.duration(moment.utc().diff(moment.utc(item.start_gps.timestamp), 'seconds'), 'seconds').format('h [hr] m [min] s [sec]', { trim: 'both' })}
											</span>
										</div>
									) : (
										<div className="content">
											<span style={{ marginRight: '20px' }}>
												{moment.duration(item.duration, 'seconds').format('h [hr] m [min] s [sec]', { trim: 'both' })}
											</span>
											<span style={{ marginRight: '5px' }}>
												{vehicleSidebarIcons.tripDuration}
											</span>
											<span style={{ marginRight: '3px' }}>
												{Math.round(item.total_distance * ((user.speed_unit === 'kph') ? 1 : 0.62))}
											</span>
											{user.speed_unit === 'kph' ? 'Km' : 'Mi'}
										</div>
									)}
								</div>
								<div className="tripStart">
									<div className="iconWrapper" style={{ top: 0 }}>
										<img src="/images/ico/marker_start.svg" alt="Trip Start" />
									</div>
									<div className="address">
										{formatAddress(item.start_address)}
									</div>
									<div className="timestamp">
										{momenttz(`${item.start_gps.timestamp}+00`).format('h:mm a')}
									</div>
								</div>
								<div className="alertsWrapper">
									{item.isTripProcessing && (
										<div className="alertBlock">
											{/* <span className="glyphicon glyphicon-alert trip-processing" /> */}
											<RiAlertFill className="glyphicon-alert" />
											<span className="value">Trip is Processing</span>
										</div>
									)}
									{item.events && item.events.length > 0
										&& (
											<Dropdown
												drop
												className="alertDropdown"
												id="trip-events-dropdown"
											>
												<Dropdown.Toggle className="alertDropdownToggle no-caret">
													<div className="alertBlock">
														{/* <span className="glyphicon glyphicon-alert" /> */}
														<RiAlertFill className="glyphicon-alert" />
														<span className="value">{item.events.length}</span>
													</div>
												</Dropdown.Toggle>
												<Dropdown.Menu className="alertDropdownMenu" drop>
													{item.events.map((event) => {
														return (
															<Dropdown.Item
																key={event.id}
																onClick={() => handleAlertDropdownClick(event)}
															>
																{event.timestamp ? momenttz(`${event.timestamp}+00`).format('h:mm:ss a') : 'N/A'} - {event.event_type ? event.event_type : 'N/A'}
															</Dropdown.Item>
														)
													})}
												</Dropdown.Menu>
											</Dropdown>
										)}

									<SpeedEventsDropdown speedingEvents={item.speeding_events} />
								</div>
								{['system_admin', 'customer_service'].includes(user.roles[0]) && item.time_idling &&
									<span style={{ display: 'inline-block', marginTop: '5px' }}>{'Time Idling: ' + item.time_idling}</span>
								}

								{index !== (deviceTrips.length - 1) &&
									<div className="prevTrip">
										<div className="value">
											{item.time_from_prev_trip > 3600 ?
												moment.duration(item.time_from_prev_trip, 'seconds').format('h[h] m[m]', { trim: 'both' }) :
												moment.duration(item.time_from_prev_trip, 'seconds').format('m[m] s[s]', { trim: 'both' })
											}
										</div>
									</div>
								}
							</div>
						)
					}) :
					<div className='no-trips-message'>No Trips Available for this Vehicle on Selected Date</div>
				}
			</div>

			<TripReplayControls
				activeTreeItem={activeTreeItem}
				onClickPlayback={onClickPlayback}
			/>
		</div>
	)
}

TripsGrid.propTypes = {
	_map: PropTypes.objectOf(PropTypes.any).isRequired,
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	// eventDate: PropTypes.string.isRequired,
	activeTreeItem: PropTypes.objectOf(PropTypes.any).isRequired,
	selectedTrip: PropTypes.objectOf(PropTypes.any),
	setPropStates: PropTypes.func.isRequired,
	setTripsGPS: PropTypes.func.isRequired,
	setSelectedTrip: PropTypes.func.isRequired,
	onClickPlayback: PropTypes.func.isRequired,
	setDashboardRedirect: PropTypes.func.isRequired,
	deviceTrips: PropTypes.arrayOf(PropTypes.any).isRequired,
	dashboardRedirect: PropTypes.objectOf(PropTypes.any)
};

const mapStateToProps = ({ user, dashboardData }) => ({
	user: user.user,
	selectedTrip: dashboardData.selectedTrip,
	tripsGPS: dashboardData.tripsGPS,
	tripsEvents: dashboardData.tripsEvents,
	dashboardRedirect: dashboardData.dashboardRedirect,
	deviceTrips: dashboardData.deviceTrips
});

const mapDispatchToProps = dispatch => ({
	setTripsGPS: (state) => {
		dispatch({ type: 'SET_TRIPS_GPS', payload: state })
	},
	setSelectedTrip: (state) => {
		dispatch({ type: 'SET_SELECTED_TRIP', payload: state })
	},
	setShowTripsHistory: (state) => {
		dispatch({ type: 'SET_SHOW_TRIP_HISTORY', payload: state })
	},
	setSelectedDropdownEventId: (state) => {
		dispatch({ type: 'SET_SELECTED_DROPDOWN_EVENT_ID', payload: state })
	},
	setTripTimelineTimestamp: (state) => {
		dispatch({ type: 'SET_TRIP_TIMELINE_TIMESTAMP', payload: state })
	},
	setDashboardRedirect: (state) => {
		dispatch({ type: 'SET_DASHBOARD_REDIRECT', payload: state })
	},
	setDeviceTrips: (arr) => {
		dispatch({ type: 'SET_DEVICE_TRIPS', payload: arr })
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(TripsGrid);
